<template>
  <div v-if="this.website" :style="`background-image: url('${this.baseUrl}${this.website.web_background_image}'); background-color: ${this.website.web_background}; height:100%; width:100%`">

    <v-container :style="`background-color: ${this.website.web_background}; padding-bottom: 300px; width:70%;`">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="2" class="justify-center align-center d-none d-sm-flex">
          <v-btn size="x-large" color="surface-variant"
                 variant="text"
                 :href="`${this.website.web_url}`"
                 dark>
            <v-icon size="x-large" left>mdi-home</v-icon>
            <v-tooltip
                activator="parent"
                location="bottom"
            >Zurück zur Webseite</v-tooltip>
          </v-btn>
        </v-col>

        <v-col class="d-flex justify-center">
          <router-link :to="`/online/${this.$route.params.id}`">
            <img :src="this.baseUrl + this.website.web_image" alt="web_image" style="width:100%; max-width: 900px;" v-if="this.website.web_image && this.orientation === 'h'">
            <img :src="this.baseUrl + this.website.web_v_image" alt="web_image" style="width:100%; max-width: 540px;" v-if="this.website.web_v_image && this.orientation === 'v'">
          </router-link>
        </v-col>

        <v-col cols="2" class="justify-center align-center d-none d-sm-flex">

        </v-col>
      </v-row>


      <v-row>
        <div class="text-center" style="width: 100%;">
          <h2>{{this.website.title}}</h2>
        </div>
      </v-row>
      <v-row>
        <v-col>
          <div class="col-6 text-center" style="word-wrap: break-word;">
            <p>{{this.website.description}}</p>
          </div>
        </v-col>
      </v-row>

      <template v-if="this.website.website_templates">
        <v-row v-for="kw in this.website.website_templates" :key="kw.id" class="mt-6">
          <div class="text-center" style="width: 300px; margin:0 auto;display: flex;align-items: center;" v-if="kw.template && kw.template.kw[0]">
            <router-link :to="`/online/${this.$route.params.id}/${kw.template.id}`">
              <v-alert
                  to="/"
                  v-model="alert"
                  border="start"
                  color="black"
                  variant="tonal"
                  style="min-width: 300px;"
              >
                <b>{{kw.template.kw[0].name}}</b>
                <br>
                <span class="ml-4">{{kw.template.kw[0].showstartdate ? kw.template.kw[0].showstartdate : kw.template.kw[0].startdate}}
                - {{kw.template.kw[0].showenddate ? kw.template.kw[0].showenddate : kw.template.kw[0].enddate}}</span>
              </v-alert>
            </router-link>
          </div>
        </v-row>
        <v-row>
          <div class="text-center" style="width: 100%;">
            <h2>{{this.website.title2}}</h2>
          </div>
        </v-row>
        <v-row>
          <v-col>
            <div class="col-6 text-center" style="word-wrap: break-word;">
              <span>{{this.website.description2}}</span>
            </div>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </div>
</template>
<script>

export default {
  data: () => ({
    orientation: null,
    slides: 0,
    slide: 1,
    id: 0,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';
    this.$store.dispatch('website/fetchWebsite', this.$route.params.id);
  },
  methods: {
    generateReport(templateId) {
      const refName = `html2Pdf${templateId}`;
      const pdfComponent = this.$refs[refName][0];

      if (pdfComponent && typeof pdfComponent.generatePdf === 'function') {
        pdfComponent.generatePdf();
      } else {
        console.error(`generatePdf is not a function on ref: ${refName}`);
      }
    },
    generateReportPrint(templateId) {
      const refName = `html2Pdf${templateId}print`;
      const pdfComponent = this.$refs[refName][0];

      if (pdfComponent && typeof pdfComponent.generatePdf === 'function') {
        pdfComponent.generatePdf();
      } else {
        console.error(`generatePdf is not a function on ref: ${refName}`);
      }
    }
  },
  computed: {
    website() {
      if(document != null) {
        if(this.$store.state.website.website != null)
        {
          document.title = this.$store.state.website.website.title;
        }
      }
      return this.$store.state.website.website;
    },
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
};
</script>
