<template>
  <v-main>
    <v-breadcrumbs :items="breamcrumbs" large></v-breadcrumbs>

    <v-card flat>
      <v-card-title class="d-flex align-center pe-2"
      link
                    to="/"
      >
        <v-icon icon="mdi-table-large"></v-icon> &nbsp;
        Tabelle

        <v-btn
            :to="`/database/` + $route.params.id + `/variables`"
            color="secondary"
            class="ml-3"
            size="small"
            @click="dialog = true"
            v-can="'priv_campaigns_schedule'"
        >
          <v-icon icon="mdi-table-column-plus-after"></v-icon> &nbsp;
          Spalten
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn-toggle
         class="actionbuttons"
         divided
         density="compact"
        >

          <v-btn
              :to="`/database/` + $route.params.id + `/addProduct`"
              color="primary"
          >
            <v-icon icon="mdi-plus"></v-icon> &nbsp;
            hinzufügen
          </v-btn>

        </v-btn-toggle>

      </v-card-title>
      <v-card-title class="d-flex align-center pe-2">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            label="Suche"
            single-line
            flat
            hide-details
            variant="solo-filled"
        ></v-text-field>
      </v-card-title>

      <v-divider/>
      <v-data-table v-model:search="search" :items="items">
        <template v-slot:item="{ item }">
          <tr>
            <template v-for="(value,key,index) in item" v-bind:key="key">
              <td v-if="typeof value === 'string' && (value.includes('.png') || value.includes('.jpg') || value.includes('.jpeg'))">
                <v-card class="my-2" elevation="2" rounded>
                  <v-img
                      :src="`${this.baseUrl}${value}`"
                      cover
                      max-height="200px"
                  ></v-img>
                </v-card>

                <div v-if="index === Object.keys(item).length - 1">
                  <v-btn
                      color="primary"
                      size="small"
                      class="float-right ml-2"
                      @click="deleteItem(item.id)"
                  >
                    <v-icon icon="mdi-delete"></v-icon>
                  </v-btn>
                  <v-btn
                      color="primary"
                      size="small"
                      class="float-right"
                      :to="`/database/` + $route.params.id + `/editProduct/` + item.id"
                  >
                    <v-icon icon="mdi-pencil"></v-icon>
                  </v-btn>
                </div>
              </td>
              <td v-else-if="index === Object.keys(item).length - 1">
                {{value}}


                <v-btn
                    color="primary"
                    size="small"
                    class="float-right ml-2"
                    @click="deleteItem(item.id)"
                >
                  <v-icon icon="mdi-delete"></v-icon>
                </v-btn>
                <v-btn
                    color="primary"
                    size="small"
                    class="float-right"
                    :to="`/database/` + $route.params.id + `/editProduct/` + item.id"
                >
                  <v-icon icon="mdi-pencil"></v-icon>
                </v-btn>
              </td>
              <td v-else>
                {{value}}
              </td>
            </template>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<style>
.actionbuttons .v-btn {
  background: #A22E32 !important;
  color: white !important;
}
</style>

<script>
export default {
  data () {
    return {
      search: '',
      breamcrumbs: [
        {
          title: 'Startseite',
          disabled: false,
          to: '/',
        },
        {
          title: 'Datenbank',
          disabled: true,
        },
      ],
    }
  },
  methods: {
    deleteItem(item) {
      confirm('Sind Sie sicher, dass Sie dieses Product löschen möchten?') && this.$store.dispatch('database/deleteProduct', item);
    },
    exportDatabase() {
      this.$store.dispatch('database/exportDatabase', this.$route.params.id);
    },
  },
  mounted() {
    this.$store.dispatch('database/fetchProducts', this.$route.params.id);
  },
  computed: {
    items() {
      return this.$store.state.database.database ?? [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
}
</script>