<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-table-column-plus-after"></v-icon> &nbsp;
        Spalten erhalten
        <v-spacer></v-spacer>
        <v-btn :to='`/database/`+ $route.params.id + `/variables`' icon="$close" variant="text"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text>
          Erstellen Sie eine Variable für die Datenbank
        </v-card-text>

        <v-card-text>
          <v-text-field
              v-model="formData.name"
              label="Namen"
              placeholder="Namen"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-checkbox
              v-model="formData.is_image"
              prepend-icon="mdi-image"
              label="Es ist ein Bild"
              value="true"
          ></v-checkbox>

          <v-col
            class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!formData.name"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
      file: null,
      image: null,
      formData: {
        image: null,
        name: null,
        is_image: false,
      },
      canvas: false,
    }),
    methods: {
      saveTemplate() {
        this.formData.database_id = this.$route.params.id;
        this.$store.dispatch('templateVariables/createTemplateVariable', this.formData).then(() => {
            this.$router.push('/database/' + this.$route.params.id + '/variables');
        });
      },
    },
  };
</script>
