<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        Artikel erstellen
        <v-spacer></v-spacer>
        <v-btn :to='`/database/` + $route.params.id' icon="$close" variant="text" @click="dialog = false"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text v-if="columns">
          <v-text-field
              v-model="formData.name"
              label="Name"
              placeholder="Name"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <template v-for="col in columns" v-bind:key="col.id">
            <template v-if="col && col.type === 'image'">


              <v-file-input
                  @update:modelValue="e => onImageChange(col.name, e)"
                  :multiple="false"
                  accept="image/*"
                  :label="col.name"
                  :placeholder="col.name"
                  prepend-icon="mdi-file-image-outline"
                  outlined
                  dense
                  filled
                  color="primary"
                  @change="e => {image = e}"
              ></v-file-input>

              <ImageCropper :image="formData.variables[col.name]" @done="e => onImageChange(col.name, e)" />

            </template>

            <v-text-field
                v-else
                :value="formData.variables[col.name]"
                @update:modelValue="value => updateField(col.name, value)"
                prepend-icon="mdi-file-document-outline"
                outlined
                dense
                filled
                color="primary"
            ></v-text-field>
          </template>

          <v-col
              class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!formData"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<style>
.canvas-wrapper canvas {
  width: 100%; /* scale width to fit the container */
  height: auto; /* maintain aspect ratio */
  display: block; /* remove default inline behavior */
}
</style>

<script>
/* eslint-disable */
// Get image base64 @todo localstorage
import {fabric} from "fabric";
import axios from "axios";
import { reactive, toRefs, computed } from 'vue';
import ImageCropper from "@/views/components/ImageCropper";

  export default {
    components: {ImageCropper},

    data: () => ({
      file: null,
      image: null,
      formData: {
        name: null,
        database_id: null,
        variables: reactive({})
      },
      canvas: false,
    }),
    mounted() {
      this.$store.dispatch('templateVariables/fetchTemplateVariables', this.$route.params.id)

      this.$store.dispatch('database/fetchProduct', {'database_id': this.$route.params.id, 'productId': this.$route.params.productId
      }).then((data) => {
        this.formData.name = this.$store.state.database.product.name;

        this.$store.state.database.product.variables.forEach((item) => {
          if(item.type === 'image') {
            fetch(this.baseUrl + item.pivot.value)
                .then(response => response.blob())
                .then(blob => {
                  var file = new File([blob], 'filename.png', { type: blob.type });
                  this.formData.variables[item.name] = file;
                });

          } else {
            this.formData.variables[item.name] = item.pivot.value;
          }
        });
      });
    },
    methods: {
      onImageChange(fieldName, e) {
        this.formData['database_id'] = this.$route.params.id;
        this.formData.variables[fieldName] = e[0]
      },
      updateField(fieldName, value) {
        this.formData['database_id'] = this.$route.params.id;
        this.formData.variables[fieldName] = value;
      },
      saveTemplate() {
        this.$store.dispatch('database/updateProduct', {'templateId': this.$route.params.id, 'productId': this.$route.params.productId, 'data': this.formData
        }).then((data) => {
            this.$router.push('/database/' + this.$route.params.id + '/');
        });
      },
    },
    computed: {
      columns() {
        return this.$store.state.templateVariables.templateVariables || [];
      },
      baseUrl() {
        return this.$store.state.config.media;
      },
    },
  };
</script>
