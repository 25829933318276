<template>
  <v-snackbar
        v-model="snackbar"
        color="white"
        position="absolute"
        location="bottom"
    >
      {{ text }}
      <template v-slot:actions>
        <v-btn
            color="red"
            variant="text"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    text: '',
  }),
  mounted() {
    this.$store.watch((state) => state.snackbar, (val) => {
      this.snackbar = val.show;
      this.text = val.text;
    });
  }
}
</script>