<template>
  <v-navigation-drawer
    :model-value="drawer"
    :color="color"
    :mini-variant="smenu"
    location="left"
    :permanent="this.permanent"
    :image="this.bg()"
    absolute="true"
    dark
    app
  >
    <v-list
      dense
      nav
    >
      <v-list-item two-line :class="miniVariant && 'px-0'" v-if="!smenu">
        <template v-slot:prepend>
          <v-avatar color="red">U</v-avatar>
        </template>

        <v-list-item-title>
          <v-list-item-title>Benutzername</v-list-item-title>
          <v-list-item-subtitle>System Administrator</v-list-item-subtitle>
        </v-list-item-title>
      </v-list-item>


      <v-text-field

        v-if="!smenu"
        label="Suche"
        disabled
        solo-inverted
      ></v-text-field>


      <v-divider></v-divider>

      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="item.link"
        :disabled="item.disabled !== false"
      >

        <template v-slot:prepend>
          <v-icon>{{ item.icon }}</v-icon>
        </template>
        <v-list-item-title>{{ item.title }}</v-list-item-title>

      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable */

  import { Component, Vue, Prop } from 'vue-facing-decorator'

  @Component({})
  class Menu extends Vue {
    @Prop() drawer;
    @Prop() smenu;


    items = [
      {title: 'Kunden', icon: 'mdi-account-settings', link: '/', disabled: false},
      {title: 'Kampagnenfreigabe', icon: 'mdi-repeat'},
      {title: 'Player', icon: 'mdi-disc-player'},
      {title: 'Displays', icon: 'mdi-monitor'},
      {title: 'Besucher-Ampel', icon: 'mdi-human-handsup'},
      {title: 'Verkäufer', icon: 'mdi-car-connected'},
      {title: 'Abrechnung', icon: 'mdi-currency-eur'},
      {title: 'Monitoring', icon: 'mdi-bell'},
      {
        title: 'Infoseiten', icon: 'mdi-information-variant',
        child: {title: 'Infoseiten', icon: 'mdi-information-variant'},
      },
    ];
    color = 'primary';
    colors = [
      'primary',
      'blue',
      'success',
      'red',
      'teal',
    ];
    permanent = false;
    miniVariant = false;
    background = true;

    bg() {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    }
  }

  export default Menu;
</script>

<style lang="scss">
  .menu-item {
      text-decoration: none !important;
      color: white;
  }
</style>
