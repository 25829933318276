<template>
  <v-dialog max-width="500" v-model="isDialogOpen" v-if="this.id">
    <template v-slot:default="{ isActive }">
      <v-card v-if="!loading">
        <v-card-title class="d-flex align-center pe-2">
          <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
          Artikel überprüfen
          <v-spacer></v-spacer>
          <v-btn icon="$close" variant="text" @click="isActive.value = false"></v-btn>
        </v-card-title>
        <v-card flat>

          <v-card-text v-if="columns">
            <v-text-field
                v-model="formData.name"
                label="Name"
                placeholder="Name"
                prepend-icon="mdi-file-document-outline"
                outlined
                dense
                filled
                color="primary"
            ></v-text-field>

            <template v-for="col in columns" v-bind:key="col.id">
              <v-text-field
                  v-if="col && col.type !== 'image'"
                  :value="formData.variables[col.name]"
                  @update:modelValue="value => updateField(col.name, value)"
                  prepend-icon="mdi-file-document-outline"
                  outlined
                  dense
                  filled
                  color="primary"
              ></v-text-field>
            </template>

            <v-col
                class="text-center">
              <v-btn @click="saveTemplate"
                     :disabled="!formData"
                     color="primary"
                     class="text-right"
              >
                Speichern
              </v-btn>
            </v-col>
          </v-card-text>
        </v-card>
      </v-card>

      <div v-else
          class="text-center">
        <v-progress-circular
            color="red"
            indeterminate
        ></v-progress-circular>
      </div>
    </template>
  </v-dialog>
</template>

<script>
import {reactive} from "vue";

export default {
  name: "ProductEditCustomer",
  data() {
    return {
      loading: true,
      isDialogOpen: false,
      formData: {
        name: null,
        template_id: null,
        variables: reactive({})
      },
      id: null,
    };
  },
  methods: {
    open(id) {
      this.loading = true;
      this.id = id;
      this.$store.dispatch('database/fetchProduct', {'productId': this.id
      }).then(() => {
        this.loading = false;
        this.formData.name = this.$store.state.database.product.name;

        this.$store.state.database.product.variables.forEach((item) => {
          if(item.type === 'image') {
            fetch(this.baseUrl + item.pivot.value)
                .then(response => response.blob())
                .then(blob => {
                  var file = new File([blob], 'filename.png', { type: blob.type });
                  this.formData.variables[item.name] = file;
                });

          } else {
            this.formData.variables[item.name] = item.pivot.value;
          }
        });
      });


      this.isDialogOpen = true;
      this.$emit("update:isDialogOpen", false);
    },
    updateField(fieldName, value) {
      this.formData['template_id'] = this.$route.params.id;
      this.formData.variables[fieldName] = value;
    },
    saveTemplate() {
      this.loading = true;
      this.$store.dispatch('database/updateProduct', {'productId': this.id, 'data': this.formData
      }).then(() => {
        this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId)
        this.isDialogOpen = false;
        this.loading = false;
        this.id = null;
      });
    },
  },
  computed: {
    columns() {
      return this.$store.state.database.product.variables || [];
      // return this.$store.state.templateVariables.templateVariables || [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
}
</script>