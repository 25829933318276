import axios from '../axiosConfig';

const state = {
    hotels: [],
};

const mutations = {
    setHotels(state, payload) {
        state.hotels = payload;
    },
};

const actions = {
    async fetchHotels({ commit }) {
        try {
            commit('setLoading', true);
            const response = await axios.get('/ssc/hotels', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setHotels', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message }, { root: true });
    localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
