<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        Vorlagenerstellung
        <v-spacer></v-spacer>
        <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text>
          Bitte geben Sie Ihren Namen ein und wählen Sie ein Hintergrundbild für die Vorlage aus.
        </v-card-text>

        <v-card-text>
          <v-text-field
              v-model="name"
              label="Namen"
              placeholder="Namen"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>
          <v-file-input
              v-model="file"
              label="Datei horizontal auswählen"
              placeholder="Datei horizontal auswählen"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>

          <v-file-input
              v-model="file2"
              label="Datei vertikal auswählen"
              placeholder="Datei vertikal auswählen"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>

          <v-select
              v-model="database"
              :items="databases"
              item-title="name"
              item-value="id"
              label="Datenbank"
              placeholder="Datenbank auswählen"
              prepend-icon="mdi-database"
              outlined
              dense
              filled
              color="primary"
          ></v-select>

          <v-col
            class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!name || !file || !file2 || !database"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
  export default {
    name: "NewTemplate",
    data: () => ({
      file: null,
      file2: null,
      name: null,
      database: null,
    }),
    mounted() {
      this.$store.dispatch('database/fetchDatabase', this.$store.state.overrideHotelId);
    },
    methods: {
      saveTemplate() {
        this.$store.dispatch('template/uploadTemplate', {'database': this.database, 'name': this.name, 'image': this.file[0], 'image2': this.file2[0], 'hotelId': this.$store.state.overrideHotelId}).then(() => {
            this.$router.push("/");
        });
      },
    },
    computed: {
      databases() {
        return this.$store.state.database.tables;
      },
    },
  };
</script>
