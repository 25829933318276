import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import './assets/app.css';

import {createApp} from "vue";
const app = createApp(App);
store.dispatch('initializeStore');

app
.directive('can', {
    beforeMount: function (el, binding) {
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        const requiredPermission = binding.value;

        if (!permissions || !permissions.includes(requiredPermission)) {
            el.style.display = "none";
        }
    }
})
.directive('can-else', {
    beforeMount: function (el, binding) {
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        const requiredPermission = binding.value;

        if (!permissions || !permissions.includes(requiredPermission)) {
            el.style.display = "block";
        } else {
            el.style.display = "none";
        }
    }
})
.use(router)
.use(store)
.use(vuetify)
.mount('#app');

