import axios from '../axiosConfig';

const state = {
    kw: [],
    products: [],
    loading: false,
    editKw: {},
    analogKw: {},
};

const mutations = {
    setKw(state, payload) {
        state.kw = payload;
    },
    setKwProducts(state, payload) {
        state.products = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setEditKw(state, payload) {
        state.editKw = payload;
    },
    setAnalogKw(state, payload) {
        state.analogKw = payload;
    }
};

const actions = {
    async activateKW({ commit }, payload) {
      try {
            commit('setLoading', true);
            const response = await axios.post(`/ssc/kw/${payload}/activate`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            commit('setKw', response.data);
      }  catch (error) {
            handleAxiosError(commit, error);
      } finally {
            commit('setLoading', false);
      }
    },
    async deactivateKW({ commit }, payload) {
        try {
                commit('setLoading', true);
                const response = await axios.post(`/ssc/kw/${payload}/deactivate`, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                commit('setKw', response.data);
        }  catch (error) {
                // handleAxiosError(commit, error);
        } finally {
                commit('setLoading', false);
        }
    },
    async getAnalogKw({ commit }, kwId) {
        try {
            commit('setLoading', true);
            const response = await axios.get(`/ssc/kw/${kwId}/analog`);
            commit('setAnalogKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },
    async createKw({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post(`/ssc/templates/${payload.template_id}/kw`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async deleteKw({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete(`/ssc/templates/${payload.id}/kw`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            commit('setKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchKw({ commit }, templateId) {
        try {
            commit('setLoading', true);
            const response = await axios.get(`/ssc/templates/${templateId}/kw`);
            commit('setKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async getKw({ commit }, kwId) {
        try {
            commit('setLoading', true);
            const response = await axios.get(`/ssc/kw/${kwId}`);
            commit('setEditKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },


    async saveKw({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post(`/ssc/kw/${payload.kwId}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setKw', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchKwProducts({ commit }, kwId) {
        try {
            commit('setLoading', true);
            const response = await axios.get(`/ssc/templates/kw/${kwId}/products`);
            commit('setKwProducts', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async assignProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post(`/ssc/templates/kw/${payload.kwId}/products`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            commit('setKwProducts', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    }
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message}, { root: true });
    // localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
