<template>
  <v-app id="inspire">
    <ErrorBag/>
    <router-view/>
  </v-app>
</template>

<script>
  import ErrorBag from "@/views/components/ErrorBag";
  export default {
    components: {ErrorBag},
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
    }),
    computed: {
      error() {
        return this.$store.getters.errorMessage;
      },
    },
    watch: {
      error(val) {
        if (val) {
          // toast message on screen
          this.$toast.error(val);
        }
      },
    }
  }
</script>
