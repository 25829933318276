<template>
  <div v-if="this.website" :style="`background-color: ${ print ? 'white' : this.website.web_background}; min-height:300vh; height:100%; width:100%;`">
    <div class="pdfDownload">
      <v-btn rounded variant="tonal" dark @click="generateReport">
        <v-icon left>mdi-download</v-icon>
      </v-btn>

      <v-btn rounded variant="tonal" :color="`${print ? 'green' : 'black'}`" @click="print = !print"
      >
        <v-icon left>mdi-printer</v-icon>
      </v-btn>
    </div>
    <div :style="`background-color: ${ print ? 'white' : this.website.web_background};width:50%;margin: 0 auto;`" v-if="isMounted">
      <SlideshowOnlineAll :id="this.$route.params.canvasId" v-if="this.$route.params.canvasId" :website="this.website"  :print="print"/>
    </div>
    <vue3-html2pdf
        filename="print"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        :manual-pagination="true"
        @has-downloaded="hasDownloaded"
        v-if="isMounted"
        ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div :style="`background-color: ${ print ? 'white' : this.website.web_background};width:100%;margin: 0 auto;`">
          <SlideshowOnlineAll :id="this.$route.params.canvasId" v-if="this.$route.params.canvasId" :website="this.website" :print="print"/>
        </div>
      </template>
    </vue3-html2pdf>
  </div>
</template>
<script>
import SlideshowOnlineAll from "@/views/components/Template/PDF/SlideshowOnlineAll";
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  components: {SlideshowOnlineAll, Vue3Html2pdf},
  data: () => ({
    orientation: null,
    slides: 0,
    slide: 1,
    id: 0,
    print:false,
    isMounted: true,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('website/fetchWebsite', this.$route.params.id);
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    hasDownloaded() {
      this.remount();
    },
    remount() {
      this.isMounted = false;
      this.$nextTick(() => {
        this.isMounted = true;
      });
    },
  },
  computed: {
    website() {
      return this.$store.state.website.website;
    },
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
};
</script>
<style scoped>
.pdfDownload {
  position: fixed;
  right: 0;
  z-index: 99999999;
}

@media print {
  .pdfDownload {
    display: none;
  }
}
</style>
