<template>
    <div class="Header">
<!--      <Menu :drawer="drawer" :smenu="this.sMenu" v-can="'priv_campaigns_schedule'" />-->

      <v-app-bar
        app
        color="#a42d2f"
        dark
      >
<!--        <v-app-bar-nav-icon @click="drawer = !drawer" v-can="'priv_campaigns_schedule'"></v-app-bar-nav-icon>-->
        <!--      <v-toolbar-title>Application</v-toolbar-title>-->

        <v-spacer></v-spacer>

        <v-btn
          icon
          to="/hotels"
          v-can="'priv_hotels_all_view'"

        >
          <v-icon>mdi-home-modern</v-icon>
        </v-btn>

        <v-menu
          left
          bottom
        >
          <template v-slot:activator="{ on, props }">
            <v-btn
              icon
              v-bind="props"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="Logout">
              <v-list-item-title>Ausloggen</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </div>
</template>

<script>
  import Menu from "../../components/Menu/Menu";
  import {Component, Vue} from 'vue-facing-decorator';

  @Component({
    components: {
      Menu,
    }
  })
  class Header extends Vue {
    drawer = false;
    sMenu = false;

    menu() {
      this.sMenu = !this.sMenu;
    }

    Logout() {
      localStorage.removeItem('token');
      this.$router.push('/login');
    }
  }
  export default Header;
</script>
