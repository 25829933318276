<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <TemplatesTable/>

        </v-col>

        <v-col
            cols="12"
            sm="6"
        >
          <DatabasesTable/>

        </v-col>


        <v-col
            cols="12"
            sm="6"
        >
          <WebsitesTable/>

        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import DatabasesTable from "@/views/components/DatabasesTable";
import TemplatesTable from "@/views/components/TemplatesTable";
import WebsitesTable from "@/views/components/WebsitesTable";
export default {
  components: {WebsitesTable, TemplatesTable, DatabasesTable},
}
</script>