<template>
  <v-main>
    <div ref="swaggerContainer"></div>
  </v-main>
</template>

<script>
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

export default {
  name: "ApiDocs",
  mounted() {
    const swaggerSpecUrl = '/swagger.json';

    SwaggerUI({
      domNode: this.$refs.swaggerContainer,
      url: swaggerSpecUrl,
      presets: [
        SwaggerUI.presets.apis
      ],
    });
  }
};
</script>

<style scoped>
/* You can add additional styling here if needed */
</style>