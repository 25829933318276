<template>
    <div class="Footer">
      <v-footer
        color="#2b2b2b"
        app
        class="justify-center"
      >
      <span class="white--text">Urheberrechte © 2017 - &copy; {{ new Date().getFullYear() }}
      <a target="_blank" href="http://www.tcb.de" style="color: #d46365; text-decoration: none">TCB Hard &amp; Software GmbH</a></span>
      </v-footer>
    </div>
</template>

<script>
/* eslint-disable */

    export default {
        name: 'Footer',
        props: {
        },
    };
</script>

<style lang="scss">
  .Footer {
    font-size: 13px;
  }
</style>
