import axios from '../axiosConfig';

const state = {
    websites: [],
    template: {},
    loading: false,
};

const mutations = {
    setWebsites(state, payload) {
        state.websites = payload;
    },
    setWebsite(state, payload) {
        state.website = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    async fetchWebsite({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.get('/ssc/websites/' + payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setWebsite', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchTemplates({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.get( payload ? '/ssc/websites?hotelId=' + payload : '/ssc/websites', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setWebsites', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async updateTemplate({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/templates/'+payload.id, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setTemplates', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async create({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/websites', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setWebsites', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async deleteWebsite({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete('/ssc/websites/' + payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setWebsites', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    }
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message }, { root: true });
    localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
