<template>
  <div class="Login">
    <v-main class="fill-height">
      <v-container
        fluid
      >
        <v-row
          align="right"
          justify="right"
        >
          <v-col
            cols="12"
          >
            <div class="login-back">
              <v-icon small color="white darken-2">mdi-arrow-left</v-icon>
              <a href="https://www.der-pay.de/new/login" class="text-grey-lighten-1">
                zum&nbsp;Hotspot-Login
              </a>
              <br>
              <v-icon small color="white darken-2">mdi-arrow-left</v-icon>
              <a href="http://www.yourscreen.de" class="text-grey-lighten-1">
                zu&nbsp;yourscreen
              </a>
            </div>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12 login-box">

              <div class="login-box-header">
                <div style="float:right;">
                  <img style="width: 130px" src="../assets/yourscreen-logo.svg"
                       alt="yourscreen">
                </div>
                <p>Willkommen am</p>
                <h1 style="font-size: 1.7em">
                  yourscreen-setup
                </h1>
              </div>
              <v-card-text>
                <v-form autocomplete="off">
                  <v-text-field
                    label="Email-Adresse"
                    name="login"
                    v-model="email"
                    append-inner-icon="mdi-email"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Passwort"
                    v-model="password"
                    name="password"
                    append-inner-icon="mdi-key-variant"
                    type="password"
                  ></v-text-field>

                  <v-card-actions>
<!--                    <v-btn disabled color="primary" outlined>Passwort vergessen?</v-btn>-->
                    <v-spacer></v-spacer>

                    <!--                <router-link to="/">-->
                    <v-btn type="submit" class="btn login-button" color="white" @click="loginAction($event)" :loading="isLoading" raised>Einloggen</v-btn>
                    <!--                </router-link>-->
                  </v-card-actions>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <FooterBlock/>
    </v-main>
  </div>
</template>

<script>
  import FooterBlock from "./partials/Footer/FooterBlock";

  export default {
    components: {FooterBlock},
    name: 'LoginPage',
    props: {
      source: String,
    },
    data: () => ({
      email: null,
      password: null,
    }),
    methods: {
      loginAction(event) {
        event.preventDefault();
        this.$store.commit('setLoading', true);

        this.$store.dispatch('login', {
          email: this.email,
          password: this.password,
        }).then((data) => {
          console.log(data);
        });


        this.$router.push('/login');
      },
    },
    mounted() {
      let token = localStorage.getItem('token');
      console.log(token);
      if (token) {
        this.$router.push('/');
      }
    },
    computed: {
      isLoading() {
        return this.$store.state.isLoading;
      },
    },
  };
</script>

<style lang="scss">
  .Login{
     overflow: hidden;
  }
  .login-button {
      background: #A22E32!important;
  }
  .login-back {
    text-align: right;
    margin-top: 50px;
    margin-right: 30px;
    float: right;
    color: #F4D7D8;
    height: 10vh;
  }

  .Login {
    background: url('../assets/login_bg_blurred.jpg');
    height: 100vh;
    position: relative;


    .login-center {
      height: 100%;
      flex-wrap: wrap;
    }

    .btn {
      padding-right: 20px !important;
      padding-left: 20px !important;
    }

    .fill-height {
      flex-wrap: wrap;
    }

    .fill-height > .row {
      flex: 1 1 100%;
    }

    .login-box {
      padding: 20px;
    }

    .login-box-header {
      padding: 15px 15px 4px;
      width: 100%;
      color: #A22E32;
    }

    .login-box-header h1 {
      font-size: 1.3em;
      font-weight: 300;
    }

    .login-box-header p {
      text-transform: uppercase;
      font-size: 1.3em;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
</style>
