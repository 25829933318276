<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2"
      link
      to="/"
      >
        <v-icon icon="mdi-city"></v-icon> &nbsp;
        Hotels

      </v-card-title>
      <v-card-title class="d-flex align-center pe-2">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            label="Suche"
            single-line
            flat
            hide-details
            variant="solo-filled"
        ></v-text-field>
      </v-card-title>

      <v-divider/>
      <v-data-table v-model:search="search" :items="items">
        <template v-slot:item.Title="{ item }">
          <v-btn
            color="primary"
            v-can="'priv_hotels_all_view'"
            @click="changeHotel(item.Id)"
          >
            <v-icon>mdi-hand-pointing-right</v-icon>
            {{ item.Title }}
          </v-btn>
<!--          <router-link :to="`/hotels/` + item.Id + `/`" >-->

<!--          </router-link>-->
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>

export default {
  data() {
    return {
      search: '',
    };
  },
  mounted() {
    this.$store.dispatch('hotels/fetchHotels');
  },
  updated() {
    this.$store.dispatch('hotels/fetchHotels');
  },
  methods: {
    changeHotel(id) {
      this.$store.dispatch('changeHotel', id).then(() => {
        this.$router.push('/');
      });
    },
  },
  computed: {
    items() {
      return this.$store.state.hotels.hotels || [];
    },
  },
}
</script>