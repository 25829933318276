import axios from '../axiosConfig';

const state = {
    templates: [],
    template: {},
    loading: false,
};

const mutations = {
    setTemplates(state, payload) {
        state.templates = payload;
    },
    setTemplate(state, payload) {
        state.template = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    async fetchTemplate({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.get('/ssc/templates/' + payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setTemplate', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchTemplates({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.get( payload ? '/ssc/templates?hotelId=' + payload : '/ssc/templates', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setTemplates', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async updateTemplate({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/templates/'+payload.id, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setTemplates', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async uploadTemplate({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/templates', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setTemplates', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async deleteTemplate({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete('/ssc/templates/' + payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setTemplates', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    }
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message }, { root: true });
    localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
