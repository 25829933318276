<template>
  <VueDatePicker v-model="selectedDate"
                 locale="de"
                 :label="label"
                 position="left"
                 :enable-time-picker="false"
                 :week-numbers="{ type: 'iso' }"
                 format="dd.MM.yyyy"
                 auto-apply
                 :clearable="false"
                 @update:model-value="closeMenu"
  >

    <template #dp-input="{ value }">
      <v-text-field
                  :label="label"
                  :model-value="value"
                  prepend-icon="mdi-calendar"
                  readonly
                  color="primary"
                  outlined
                  filled
                  density="compact"
          ></v-text-field>
    </template>
  </VueDatePicker>
</template>

<script>
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  props: ["label", "color", "modelValue"],
  emits: ["update:modelValue"],
  components: {
    VueDatePicker,
  },
  data() {
    return {
      isMenuOpen: false,
      selectedDate: null,
    };
  },
  methods: {
    closeMenu(newDate) {
      this.selectedDate.value = newDate;
      this.$emit("update:modelValue", moment(newDate).format("DD.MM.YYYY"));
      this.isMenuOpen = false;
    },
  },
  computed: {
    formattedDate() {
      return this.selectedDate
          ? this.selectedDate.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          : "";
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.selectedDate = moment(newVal,"DD.MM.YYYY").toDate();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.dp__today {
  border: 1px solid #A22E32 !important;
}


.dp__range_end, .dp__range_start, .dp__active_date {
  background: #A22E32;
  color: white;
}
</style>
