<template>
  <div style="position:relative;" v-if="this.data && this.data.kw && slides != 0">
<!--    <div class="text-center" style="position:fixed; width: 100%; display: flex; justify-content: center; bottom:5%; z-index:20">-->
<!--      <v-icon-->
<!--          v-for="n in slides"-->
<!--          :key="n"-->
<!--          @click="gotoSlide(n)"-->
<!--          :color="slide === n ? 'red' : 'grey'"-->
<!--          size="18"-->
<!--      >-->
<!--        mdi-circle-->
<!--      </v-icon>-->
<!--    </div>-->
<!--    <div style="position:fixed; width: 90%; display: flex; justify-content: center; left:5%; bottom:4%; z-index:20">-->
<!--      <v-btn @click="slide&#45;&#45;" :disabled="slide == 1">-->
<!--        <v-icon>mdi-chevron-left</v-icon>-->
<!--        <span>vorherige</span>-->
<!--      </v-btn>-->
<!--      <div style="width: 65%"></div>-->
<!--      <v-btn @click="slide++" :disabled="slide == slides">-->
<!--        <span>nächste</span>-->
<!--        <v-icon>mdi-chevron-right</v-icon>-->
<!--      </v-btn>-->
<!--    </div>-->
    <v-carousel style="min-height: 70vh; height: 100%;" :continuous="true" :cycle="this.website.scroll" :interval="this.website.scroll_speed">
      <v-carousel-item v-for="n in slides" :key="n">
          <TestAllObjects :orientation="orientation" :index="(n-1)*this.totalOnCanvas" :data="this.data" />
          <div style="height: 50px;"></div>
      </v-carousel-item>
    </v-carousel>


  </div>
  <template v-if="isLoading">
    <div class="text-center ma-15">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </template>
  <template v-else-if="slides == 0">
    <p class="text-center ma-15">Keine aktuelle KW</p>
  </template>
</template>

<script>
import TestAllObjects from "@/views/components/Template/TestAllObjects";

export default {
  components: {TestAllObjects},
  props: {
    id: {
      type: Number,
      required: true,
    },
    website: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    orientation: null,
    totalOnCanvas: 0,
    slides: 0,
    slide: 1,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('canvas', this.id).then(() => {
      if(this.data && this.data.kw && this.data.kw[0] && this.data.kw[0].products.length) {
        this.totalOnCanvas = this.getTotalProducts();
        this.slides = this.data.kw[0].products.length / this.totalOnCanvas;
      }
    });
  },
  methods: {
    gotoSlide(n) {
      this.slide = n;
    },
    getTotalProducts() {
      let productIndices = new Set();
      let canvas = this.orientation === 'h' ? JSON.parse(this.data.canvas) : JSON.parse(this.data.canvasVertical);
      if(!canvas) return 0;
      canvas.objects.forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let index = obj.name.split('_').pop();
          productIndices.add(index);
        }
      });
      return productIndices.size;
    },
  },
  computed: {
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
}
</script>