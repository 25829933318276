<template>
  <v-app>
    <Header/>
      <router-view></router-view>
    <FooterBlock/>
  </v-app>
</template>

<script>
/* eslint-disable */

  import Footer from "../partials/Footer/FooterBlock";
  import Header from "../partials/Header/Header";

  export default {
    components: {Footer, Header},
    name: "Main",
  };
</script>
