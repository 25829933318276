<template>
  <v-card>
    <v-card-title class="d-flex align-center pe-2">
      <v-icon icon="mdi-web-box"></v-icon> &nbsp;
      Webseiten

      <v-spacer></v-spacer>

      <v-btn
          color="primary"
          size="small"
          to="/website/new"
          v-can="'priv_campaigns_schedule'"
          icon="mdi-plus"
      />

    </v-card-title>

    <v-divider></v-divider>

    <v-list lines="one">
      <template v-if="loading">
        <v-list-item v-for="n in 3" :key="n">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-for="item in databases" :key="item.id">
        <template v-slot:prepend="{ }">
          <v-list-item>
            <v-icon size="30">mdi-web</v-icon>
          </v-list-item>
<!--          <v-list-item-action start>-->
<!--            <v-checkbox-btn :model-value="isActive"></v-checkbox-btn>-->
<!--          </v-list-item-action>-->
        </template>
        <template v-slot:append>
          <v-btn
              :to="`/website/${item.url}/edit`"
              color="primary"
              icon="mdi-pencil"
              variant="text"
              v-can="'priv_campaigns_schedule'"
          ></v-btn>

          <v-btn
              color="primary"
              icon="mdi-delete"
              variant="text"
              v-can="'priv_campaigns_schedule'"
              @click="deleteItem(item)"
          ></v-btn>



<!--          @click="deleteItem(props.item)"-->


        </template>
        <v-list-item-content>
          <v-list-item
              :to="`/online/` + item.url"
          >
            {{ item.url }}
          </v-list-item>
          <v-list-item-subtitle>

            <div v-if="item.website_templates">
              <v-chip
                  v-for="template in item.website_templates"
                  :key="template.id"
                  color="red"
                  text-color="white"
                  style="padding: 10px; margin: 5px;"
              >
                <v-icon left>mdi-palette-swatch</v-icon>
                {{template.template.name}}
              </v-chip>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      </template>
    </v-list>

    <v-divider></v-divider>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "WebsitesTable",
  mounted() {
    this.$store.dispatch('website/fetchTemplates', this.$store.state.overrideHotelId);
  },
  methods: {
    deleteItem(item) {
      confirm('Sind Sie sicher, dass Sie dieses Webseite löschen möchten?') && this.$store.dispatch('website/deleteWebsite', item.id);
    }
  },
  computed: {
    ...mapState({
      databases: state => state.website.websites,
      loading: state => state.website.loading
    })
  }
}
</script>