<template>
  <v-main>
    <v-card-title class="d-flex align-center">
      <v-icon icon="mdi-database"></v-icon> &nbsp;
      Datenbankverwaltung
      <v-spacer></v-spacer>
      <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
    </v-card-title>


    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="d-flex align-center">
              <v-icon icon="mdi-export"></v-icon> &nbsp;
              Export
            </v-card-title>
            <v-card-item>
              <v-card-text>
                <p>
                  <strong>Wichtig:</strong> Bitte speichern Sie die Datei an einem sicheren Ort. Sie wird benötigt, um die Datenbank wiederherzustellen.
                </p>
              </v-card-text>

              <v-btn
                  @click="exportDatabase"
                  color="primary"
                  width="100%"
              >
                <v-icon icon="mdi-export"></v-icon> &nbsp;
                Herunterladen
              </v-btn>

            </v-card-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="d-flex align-center">
              <v-icon icon="mdi-import"></v-icon> &nbsp;
              Import
            </v-card-title>
            <v-card-item>
              <v-card-text>
                <p>
                  Hier können Sie eine Datenbank importieren. Bitte beachten Sie, dass die Datenbank im .zip Format vorliegen muss.
                </p>
              </v-card-text>

              <v-file-input
                  v-model="file"
                  label="Datei auswählen"
                  placeholder="Datei auswählen"
                  prepend-icon="mdi-paperclip"
                  accept="zip/*"
                  outlined
                  dense
                  filled
                  color="primary"
                  @change="onFileChange"
              ></v-file-input>


            </v-card-item>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="d-flex align-center">
              <v-icon icon="mdi-delete"></v-icon> &nbsp;
              Löschen
            </v-card-title>
            <v-card-item>
              <v-card-text>
                <p>
                  <strong>Wichtig:</strong> Diese Änderung kann nicht rückgängig gemacht werden. Stellen Sie sicher, dass Sie die Exportdatei haben.
                </p>
              </v-card-text>



            </v-card-item>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  @click="deleteItem(this.$route.params.id)"
                  color="primary"
              >
                <v-icon icon="mdi-delete"></v-icon> &nbsp;
                Löschen ohne Wiederherstellung
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

      </v-row>
    </v-container>



  </v-main>
</template>

<script>
/* eslint-disable */
// Get image base64 @todo localstorage
  export default {
    data: () => ({
      file: null,
    }),
    methods: {
      onFileChange(e) {
        const file = e.target.files[0];
        this.$store.dispatch('database/importDatabase', {file: file, templateId: this.$route.params.id});
        this.$router.push(`/database/${this.$route.params.id}`);
      },
      exportDatabase() {
        this.$store.dispatch('database/exportDatabase', this.$route.params.id);
      },
      deleteItem(item) {
        confirm('Sind Sie sicher, dass Sie dieses Datenbank löschen möchten?') && this.$store.dispatch('database/deleteDatabase', item)
      },
    },
  };
</script>
