<template>
  <v-card>
    <v-card-title class="d-flex align-center pe-2">
      <v-icon icon="mdi-database"></v-icon> &nbsp;
      Datenbanken

      <v-spacer></v-spacer>

      <v-btn
          color="primary"
          size="small"
          to="/database/new"
          v-can="'priv_campaigns_schedule'"
          icon="mdi-plus"
      />

    </v-card-title>

    <v-divider></v-divider>

    <v-list lines="one">
      <template v-if="loading">
        <v-list-item v-for="n in 3" :key="n">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item v-for="item in databases" :key="item.id">
        <template v-slot:prepend="{ }">
          <v-list-item>
            <v-icon>mdi-table</v-icon>
          </v-list-item>
<!--          <v-list-item-action start>-->
<!--            <v-checkbox-btn :model-value="isActive"></v-checkbox-btn>-->
<!--          </v-list-item-action>-->
        </template>
        <template v-slot:append>
          <v-btn
              :to="`/database/` + item.id + `/manage`"
              color="primary"
              icon="mdi-cog"
              variant="text"
              v-can="'priv_campaigns_schedule'"
          ></v-btn>
          <!--@click="deleteItem(props.item)"-->

        </template>
        <v-list-item-content>
          <v-list-item
              :to="`/database/` + item.id"
          >
            {{ item.name }}
          </v-list-item>
<!--          <v-list-item-subtitle>-->
<!--            Auto-update apps at any time. Data charges may apply-->
<!--          </v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>
      </template>
    </v-list>

    <v-divider></v-divider>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "DatabasesTable",
  mounted() {
    this.$store.dispatch('database/fetchDatabase', this.$store.state.overrideHotelId);
  },
  computed: {
    ...mapState({
      databases: state => state.database.tables,
      loading: state => state.database.loading
    })
  }
}
</script>