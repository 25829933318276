import Vuex from 'vuex';
import axios from './axiosConfig';
import templateVariables from './modules/templateVariables';
import hotels from './modules/hotels';
import template from './modules/template';
import database from './modules/database';
import kw from './modules/kw';
import website from './modules/website';

export default new Vuex.Store({
  state: {
      isLoading: false,
      errorMessage: '',
      snackbar: false,
      canvas: [],
      config: {
          media: 'https://localhost/s3/',
          api: 'https://localhost/api/v1',
      },
      overrideHotelId: false,
  },
  mutations: {
    setDomain: (state, payload) => {
        state.config.media = 'https://' + payload + '/s3/';
        state.config.api = 'https://' + payload + '/api/v1';
    },
    setHotelId: (state, payload) => {
        state.overrideHotelId = payload;
    },
    setLoading: (state, payload) => {
        state.isLoading = payload;
    },
    setErrorMessage: (state, payload) => {
        state.errorMessage = payload;
    },
    setSnackbar: (state, payload) => {
        state.snackbar = payload;
    },
    setCanvas: (state, payload) => {
        state.canvas = payload;
    },
  },
  actions: {
    initializeStore({ commit }) {
      if (window.location.hostname === 'localhost') {
          commit('setDomain', 'localhost');
      } else if(window.location.hostname === '192.168.88.5') {
          commit('setDomain', '192.168.88.5');
      } else {
          commit('setDomain', 'yourscreen-setup.de');
      }

        if(localStorage.getItem('hotelId')) {
            commit('setHotelId', localStorage.getItem('hotelId'));
        }
    },
    changeHotel({ commit }, payload) {
        commit('setHotelId', payload);
        localStorage.setItem('hotelId', payload);
    },
    login: ({commit}, payload) => {
        axios.post('/login', {
            email: payload.email,
            password: payload.password,
            device_name: 'web'
        })
        .then(response => {
            if (response.data.token) {
                commit('setLoading', false);
                localStorage.removeItem('hotelId');
                // store token
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
                window.location.href = '/';
                return Promise.resolve();
            } else if (response.data.errors) {
                commit('setSnackbar', {show: true, text: response.data.errors.message} );
                commit('setLoading', false);
            } else {
                return Promise.reject();
            }
        }).catch(error => {
            if(error.response) {
                commit('setSnackbar', {show: true, text: error.response.data.message} );
                commit('setLoading', false);
                localStorage.removeItem('token');
            } else {
                commit('setSnackbar', {show: true, text: "Systemfehler: no connection"} );
            }
            return Promise.resolve();
        });
    },
      canvas: async ({ commit }, payload) => {
          try {
              commit('setLoading', true);
              const token = localStorage.getItem('token');
              const headers = {
                  headers: {
                      Authorization: `Bearer ${token}`,
                      Accept: 'application/json',
                      ContentType: 'application/json'
                  }
              };
              const response = await axios.get('/ssc/templates/' + payload + '/canvas', headers);
              if (response.data) {
                  commit('setLoading', false);
                  commit('setCanvas', response.data);
                  return Promise.resolve();
              } else if (response.data.errors) {
                  commit('setSnackbar', { show: true, text: response.data.errors.message });
                  commit('setLoading', false);
              } else {
                  return Promise.reject();
              }
          } catch (error) {
              if (error.response) {
                  commit('setSnackbar', { show: true, text: error.response.data.message });
                  commit('setLoading', false);
              } else {
                  commit('setSnackbar', { show: true, text: "Systemfehler: no connection" });
              }
              return Promise.resolve();
          }
      },
    saveCanvas: ({commit}, payload) => {
          const token = localStorage.getItem('token');
          const headers = {headers :{ Authorization: `Bearer ${token}`, Accept: 'application/json', ContentType: 'application/json' }};
          axios.post('/ssc/templates/' + payload.template_id + '/canvas', payload, headers)
              .then(response => {
                  if (response.data) {
                      commit('setLoading', false);
                      commit('setCanvas', response.data);
                      return Promise.resolve();
                  } else if (response.data.errors) {
                      commit('setSnackbar', {show: true, text: response.data.errors.message} );
                      commit('setLoading', false);
                  } else {
                      return Promise.reject();
                  }
              }).catch(error => {
              if(error.response) {
                  commit('setSnackbar', {show: true, text: error.response.data.message} );
                  commit('setLoading', false);

                  // localStorage.removeItem('token');
              } else {
                  commit('setSnackbar', {show: true, text: "Systemfehler: no connection"} );
              }
          });
      },

  },
  modules: {
    hotels,
    template,
    templateVariables,
    database,
    kw,
    website,
  },
});
