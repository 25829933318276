<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        Vorlagenerstellung bearbeiten
        <v-spacer></v-spacer>
        <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text>
          Wenn Sie das ändern, werden in diesem Fall alle Elemente aus der Vorlage entfernt. Bitte geben Sie Ihren Namen ein und wählen Sie ein Hintergrundbild für die Vorlage aus.        </v-card-text>

        <v-card-text>
          <v-text-field
              v-model="name"
              label="Namen"
              placeholder="Namen"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>
          <v-file-input
              v-model="file"
              label="Datei horizontal auswählen"
              placeholder="Datei horizontal auswählen"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>

          <v-file-input
              v-model="file2"
              label="Datei vertikal auswählen"
              placeholder="Datei vertikal auswählen"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>


          <v-file-input
              v-model="file3"
              label="Web Horizontal"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>

          <v-file-input
              v-model="file4"
              label="Web Vertikal"
              prepend-icon="mdi-paperclip"
              accept="image/*"
              outlined
              dense
              filled
              color="primary"
          ></v-file-input>

          <v-color-picker
              v-model="color"
              label="Farbe"
              mode="hex"
              placeholder="Farbe"
              prepend-icon="mdi-palette"
              outlined
              dense
              filled
              color="primary"></v-color-picker>


          <v-select
              v-model="database"
              :items="databases"
              item-title="name"
              item-value="id"
              label="Datenbank"
              placeholder="Datenbank auswählen"
              prepend-icon="mdi-database"
              outlined
              dense
              filled
              color="primary"
          ></v-select>

          <v-col
            class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!name || !database"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
      file: {},
      file2: {},
      file3: {},
      file4: {},
      name: null,
      database: null,
      color: null,
    }),
    mounted() {
      this.$store.dispatch('template/fetchTemplate', this.$route.params.id).then(() => {
        this.name = this.$store.state.template.template.name;
        this.database = this.$store.state.template.template.fkSscDatabaseId;
        this.color = this.$store.state.template.template.web_background;
      });
      this.$store.dispatch('database/fetchDatabase', this.$store.state.overrideHotelId);
    },
    methods: {
      saveTemplate() {
        let request = {'id': this.$route.params.id, 'database': this.database, 'name': this.name, 'hotelId': this.$store.state.overrideHotelId};
        if(this.file.length > 0) {
          request['image'] = this.file[0];
        }
        if(this.file2.length > 0) {
          request['image2'] = this.file2[0];
        }
        if(this.file3.length > 0) {
          request['web_image'] = this.file3[0];
        }
        if(this.file4.length > 0) {
          request['web_v_image'] = this.file4[0];
        }
        request['web_background'] = this.color;
        this.$store.dispatch('template/updateTemplate', request).then(() => {
            this.$router.push("/");
        });
      },
    },
    computed: {
      databases() {
        return this.$store.state.database.tables;
      },
    },
  };
</script>
