<template>
  <div v-if="image && !isDone" style="margin: 0 auto; display:table;">
    <vue-cropper
        ref="cropper"
        :src="img"
        alt="Source Image"
        :autoCropArea="1.5"
        :autoCrop="true"
        :aspect-ratio="4 / 3"
        :rotatable="false"
        :containerStyle="{height: '480px', width:'640px'}"
    >
    </vue-cropper>
    <v-btn
        @click="domeImage"
        v-if="!isDone"
        color="secondary"
        style="width: 100%;">
      Bildzuschnitt
    </v-btn>
  </div>
  <template v-else-if="isDone">
    <v-img :src="img" height="240" width="320" style="margin: 0 auto;"></v-img>
  </template>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  emits: ['done'],
  props: {
    image: {
      type: File,
      required: true
    }
  },
  data: () => ({
    img: null,
    isDone: false
  }),
  methods: {
    domeImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        var file = new File([blob], 'filename.png', { type: blob.type });
        this.$emit('done', [file]);
        this.isDone = true;
      });
    }
  },
  components: {
    VueCropper
  },
  updated() {
    // if (this.image) {
    //   this.img = URL.createObjectURL(this.image);
    // }
  },
  watch: {
    image: {
      handler: function (val) {
        try{
          this.img = URL.createObjectURL(val);
        }catch (e) {
          console.log(e);
        }
      },
      deep: true
    }
  }
}
</script>