<template>
  <v-main>
    <v-card flat style="overflow: visible!important;">
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        KW erstellen
        <v-spacer></v-spacer>
        <v-btn :to='`/template/`+ $route.params.id' icon="$close" variant="text"></v-btn>
      </v-card-title>

        <v-card-text>
          Effiziente KW-Erstellung für Datenabzüge in Ihren Vorlagen.
        </v-card-text>

        <v-card-text>
          <DatePicker v-model="formData.startdate" @update:modelValue="getWeekNumber(formData.startdate)" label="Start Datum"></DatePicker>
          <DatePicker v-model="formData.enddate" label="Ende Datum"></DatePicker>

          <v-text-field
              v-model="formData.name"
              label="Namen"
              placeholder="Namen"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-select
              v-if="analogKw.length > 0"
              v-model="formData.analogKw"
              :items="[{ id: false, name: 'Ohne Vorlage' }, ...analogKw]"
              prepend-icon="mdi-content-duplicate"
              label="Postionen aus Vorlage übernehmen"
              item-title="name"
              item-value="id"
              dense
              outlined
              filled
              color="primary"
          />

          <v-checkbox
              v-model="showDate"
              label="Anzeigedatum (optional)"
              dense
              color="primary"
          >
          </v-checkbox>


          <template v-if="showDate">
            <DatePicker v-model="formData.showstartdate" label="Anzeige Start Datum"></DatePicker>
            <DatePicker v-model="formData.showenddate" label="Anzeige Ende Datum"></DatePicker>
          </template>


          <v-col
            class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!formData.name"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
/* eslint-disable */
import DatePicker from "@/views/layouts/template/DatePicker";
import moment from "moment";

  export default {
    name: "KWNew",
    components: {DatePicker},

    data: () => ({
      formData: {
        name: null,
        startdate: null,
        enddate: null,
        showstartdate: null,
        showenddate: null,
        analogKw: false,
      },
      showDate: false,
    }),
    mounted() {
      this.$store.dispatch('kw/getAnalogKw', this.$route.params.id);
    },
    methods: {
      getWeekNumber(date) {
        date = moment(date, 'DD.MM.YYYY').toDate();
        if (!(date instanceof Date)) {
          throw new Error('Input must be a Date object');
        }
        let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        d.setUTCDate(d.getUTCDate() + 3 - (d.getUTCDay() || 7));
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        this.formData.name = "KW-" + weekNo;
      },
      saveTemplate() {
        this.formData.template_id = this.$route.params.id;
        if(!this.showDate) {
          this.formData.showstartdate = null;
          this.formData.showenddate = null;
        }
        this.$store.dispatch('kw/createKw', this.formData).then(() => {
            this.$router.push('/template/' + this.$route.params.id);
        });
      },
    },
    computed: {
      analogKw() {
        return this.$store.state.kw.analogKw;
      }
    }
  };
</script>
