import axios from '../axiosConfig';

const state = {
    tables: [],
    database: [],
    product: {},
    loading: false,
};

const mutations = {
    setDatabase(state, payload) {
        state.database = payload;
    },
    setProduct(state, payload) {
        state.product = payload;
    },
    setTables(state, payload) {
        state.tables = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    async activateProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/database/' + payload + '/activate', {}, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },
    async deactivateProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/database/' + payload + '/deactivate', {}, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },
    async createDatabase({ commit }, payload) {
      try {
        commit('setLoading', true);
        const databaseResponse = await axios.post('/ssc/databases', payload, {
            headers: { "Content-Type": 'application/json' }
        });

        if (databaseResponse.data) {
            commit('setTables', databaseResponse.data);
        } else if (databaseResponse.data.errors) {
            commit('setSnackbar', { show: true, text: databaseResponse.data.errors.message });
        }
      } catch (error) {
        handleAxiosError(commit, error);
      } finally {
        commit('setLoading', false);
      }
    },

    async fetchDatabase({ commit }, payload) {
        try {
            commit('setLoading', true);
            const databaseResponse = await axios.get(payload ? '/ssc/databases?hotelId=' + payload : '/ssc/databases', {
                headers: { "Content-Type": 'application/json' }
            });

            if (databaseResponse.data) {
                commit('setTables', databaseResponse.data);
            } else if (databaseResponse.data.errors) {
                commit('setSnackbar', { show: true, text: databaseResponse.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchProducts({ commit }, payload) {
        try {
            commit('setLoading', true);
            const databaseResponse = await axios.get('/ssc/templates/' + payload + '/database', {
                headers: { "Content-Type": 'application/json' }
            });

            if (databaseResponse.data) {
                commit('setDatabase', databaseResponse.data);
            } else if (databaseResponse.data.errors) {
                commit('setSnackbar', { show: true, text: databaseResponse.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const databaseResponse = await axios.get('/ssc/templates/' + payload.database_id + '/database/' + payload.productId, {
                headers: { "Content-Type": 'application/json' }
            });

            if (databaseResponse.data) {
                commit('setProduct', databaseResponse.data);
            } else if (databaseResponse.data.errors) {
                commit('setSnackbar', { show: true, text: databaseResponse.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async updateProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/templates/' + payload.templateId + '/database/'+ payload.productId, payload.data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async createProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post('/ssc/templates/' + payload.database_id + '/database', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async deleteProduct({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete('/ssc/templates/' + payload + '/database', {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async exportDatabase({commit}, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.get('/ssc/templates/' + payload + '/database/export', {
                responseType: 'blob',
            });

            if (response.data instanceof Blob) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exported_data.zip');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(link);
            } else {
                console.error('Unexpected response format:', response);
                // Handle error
            }
        }  catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async importDatabase({commit}, payload) {
        try {
            commit('setLoading', true);
            console.log(payload.file, payload.templateId);
            const response = await axios.post('/ssc/templates/' + payload.templateId + '/database/import', {file: payload.file}, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.data) {
                commit('setDatabase', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }

    },

    async deleteDatabase({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete('/ssc/databases/' + payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (response.data) {
                commit('setTables', response.data);
            } else if (response.data.errors) {
                commit('setSnackbar', { show: true, text: response.data.errors.message });
            }
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    }
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message }, { root: true });
    // localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
