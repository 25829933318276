<template>
  <div ref="componentRef">
    <TestObject :canvas-data="json" :index="index" :canvasWidth="orientation === 'h' ? canvasWidth : canvasHeight" :canvasHeight="orientation === 'h' ? canvasHeight : canvasWidth" :style="`zoom: ${zoom} !important; -webkit-text-size-adjust: 50%;`"/>
  </div>
</template>

<script>
/* eslint-disable */
import TestObject from "@/views/components/Template/TestObject";

export default {
  name: "PreviewAllCanvas",
  components: {TestObject},
  props: {
    orientation: {
      type: String,
      default: 'h'
    },
    index: 0,
    data: Object,
  },
  data: () => ({
    canvasWidth: 1920/2,
    canvasHeight: 1080/2,
    interval: null,
    json: null,
    iteratorTest: 0,
    zoom: 1,
  }),
  methods: {
    adjustZoom() {
      let scaleRatio = window.innerWidth / window.innerHeight;
      if(scaleRatio > 1) {
        this.zoom = scaleRatio / 1.5;
      } else {
        this.zoom = scaleRatio;
      }
      console.log('Adjusted zoom:', this.zoom);
    },
    getTotalProducts() {
      if(!this.data) return [];
      let productIndices = new Set();
      let canvas = this.orientation === 'h' ? JSON.parse(this.data.canvas) : JSON.parse(this.data.canvasVertical);
      if(!canvas) return 0;
      canvas.objects.forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let index = obj.name.split('_').pop();
          productIndices.add(index);
        }
      });
      return productIndices.size;
    },
    modifyCanvas() {
      this.adjustZoom();
      if(!this.canvas) return 0;
      const totalProducts = this.getTotalProducts();
      this.canvas.objects.forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let [type, , index] = obj.name.split('_');
          let product = this.products[parseInt(index) % totalProducts];
          if(product === undefined) return;
          let variable = product.variables.find(v => v.name === type);
          if (variable) {
            if (variable.type === 'image') {
              obj.src = this.baseUrl + variable.pivot.value;
              this.json = JSON.stringify(this.canvas);
            } else if (variable.type === 'text') {
              obj.text = variable.pivot.value;
              this.json = JSON.stringify(this.canvas);
            }
          }
        }
      });
    },
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.iteratorTest = this.index;
    this.modifyCanvas();
  },
  computed: {
    canvas() {
      if(!this.data) return [];
      if(this.orientation === 'h') {
        return JSON.parse(this.data.canvas);
      } else {
        return JSON.parse(this.data.canvasVertical);
      }
    },
    kw() {
      return this.$store.state.canvas.kw[0] ?? [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
    products() {
      const totalProducts = this.getTotalProducts();
      let shuffledProducts = this.kw.products;
      if(this.kw && this.kw.products) {
        return shuffledProducts.slice(this.iteratorTest, totalProducts+this.iteratorTest);
      } else {
        return [];
      }
    }
  },
  watch: {
    index() {
      this.iteratorTest = this.index;
      this.modifyCanvas();
    },
    data() {
      this.iteratorTest = this.index;
      this.modifyCanvas();
    }
  }
};
</script>
