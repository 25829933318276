<template>
  <v-main>
    <v-breadcrumbs :items="breamcrumbs" large></v-breadcrumbs>

    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple-outline"></v-icon> &nbsp;
        KW

        <v-spacer></v-spacer>

        <v-btn
            :to="`/template/` + $route.params.id + `/kw/new`"
            color="primary"
            text
            @click="dialog = true"
        >
          <v-icon icon="mdi-plus"></v-icon> &nbsp;
          hinzufügen
        </v-btn>

      </v-card-title>

      <v-divider/>
      <v-data-table :items="items" :headers="headers" class="kwlist">
        <template v-slot:item="{ item }">
          <tr v-bind:class="{ 'active': isDateInRange(new Date(), item.startdate, item.enddate), 'deactive': !item.active }">
            <td>
              <v-btn
                  :to="`/template/` + $route.params.id + `/kw/` + item.id"
                  color="primary"
                  text
                  @click="dialog = true"
              >
                {{ item.name }}
              </v-btn>
            </td>
            <td>{{ item.startdate }}
              <template v-if="item.showstartdate">
                ({{ item.showstartdate }})
              </template>
            </td>
            <td>{{ item.enddate }}
              <template v-if="item.showenddate">
                ({{ item.showenddate }})
              </template>
            </td>
            <td>

              <v-btn
                  color="primary"
                  size="small"
                  class="float-right"
                  @click="deleteItem(item)"
              >
                <v-icon icon="mdi-delete"></v-icon>
              </v-btn>

              <v-btn
                  color="primary"
                  size="small"
                  class="float-right mr-2"
                  :to="`/template/` + $route.params.id + `/kw/` + item.id + `/edit`"
              >
                <v-icon icon="mdi-pencil"></v-icon>
              </v-btn>


              <template v-if="item.active">
                <v-btn
                    color="primary"
                    class="float-right mr-2"
                    size="small"
                    v-can="'priv_campaigns_schedule'"
                    @click="this.deactivateKW(item.id)"
                >
                  <v-icon icon="mdi-close"></v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                    color="primary"
                    class="float-right mr-2"
                    size="small"
                    v-can="'priv_campaigns_schedule'"
                    @click="this.activateKW(item.id)"
                >
                  <v-icon icon="mdi-check"></v-icon>
                </v-btn>
              </template>
            </td>
          </tr>

        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'KWList',
  data () {
    return {
      headers: [
        { title: 'Name', key: 'name' },
        { title: 'Startdatum', key: 'startdate' },
        { title: 'Enddatum', key: 'enddate' },
        { title: 'Aktionen', key: 'actions', sortable: false },
      ],
      breamcrumbs: [
        {
          title: 'Startseite',
          disabled: false,
          to: '/',
        },
        {
          title: 'Vorlagen',
          disabled: true,
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('kw/fetchKw', this.$route.params.id)
  },
  methods: {
    ...mapActions('kw', ['activateKW', 'deactivateKW']),
    isDateInRange(date, startDate, endDate) {
      const start = this.convertDateStringToDate(startDate);
      const end = this.convertDateStringToDate(endDate);
      date.setHours(0, 0, 0, 0);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
      return date >= start && date <= end;
    },
    convertDateStringToDate(dateStr) {
      const parts = dateStr.split(".");
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      const date = new Date(year, month, day);
      return date;
    },
    deleteItem(item) {
      confirm('Sind Sie sicher, dass Sie dieses Element löschen möchten?') && this.$store.dispatch('kw/deleteKw', item)
    }
  },
  computed: {
    items() {
      return this.$store.state.kw.kw || [];
    }
  },
}
</script>

<style scoped>
.kwlist .active {
  background: #A22E32 !important;
  color: white !important;
}


.kwlist .deactive {
  background: #2e6ea2 !important;
  color: white !important;
}
</style>