import axios from '../axiosConfig';

const state = {
    templateVariables: [],
};

const mutations = {
    setTemplateVariables(state, payload) {
        state.templateVariables = payload;
    },
};

const actions = {
    async createTemplateVariable({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.post(`/ssc/templates/${payload.template_id}/variables`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            commit('setTemplateVariables', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async deleteTemplateVariable({ commit }, payload) {
        try {
            commit('setLoading', true);
            const response = await axios.delete(`/ssc/templates/${payload.id}/variables`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            commit('setTemplateVariables', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },

    async fetchTemplateVariables({ commit }, templateId) {
        try {
            commit('setLoading', true);
            const response = await axios.get(`/ssc/templates/${templateId}/variables`);
            commit('setTemplateVariables', response.data);
        } catch (error) {
            handleAxiosError(commit, error);
        } finally {
            commit('setLoading', false);
        }
    },
};

function handleAxiosError(commit, error) {
    const message = error.response ? error.response.data.message : "Systemfehler: no connection";
    commit('setSnackbar', { show: true, text: message }, { root: true });
    localStorage.removeItem('token');
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
