<template>
  <div :style="`width: ${canvasWidth}px; height:${canvasHeight}px;`" class="container-fluid">
    <div :style="`height: ${canvasHeight}px;`">
      <div v-for="(item, index) in canvasObjects" :key="index" :style="getStyle(item)">
        <div v-if="item.type === 'textbox'">
          <p>{{ item.text }}</p>
        </div>
        <div v-if="item.type === 'image'">

          <div v-show="!loaded && !item.name.includes('background')">
            <v-progress-circular
                indeterminate
                size="256"
                color="primary">
            </v-progress-circular>
          </div>
          <div v-show="loaded">
            <img
                :src="item.src"
                :alt="`Image ${index}`"
                :height="item.height"
                :width="item.width"
                @load="onImageLoad"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {fabric} from "fabric";

export default {
  props: {
    canvasData: String,
    index: Number,
    canvasWidth: Number,
    canvasHeight: Number,
  },
  data: () => ({
    backgroundColor: 'white',
    backgroundImage: false,
    loaded: false,
  }),
  computed: {
    canvasObjects() {
      if(!this.canvasData) return [];
      const canvas = JSON.parse(this.canvasData);
      return canvas.objects || [];
    },
  },
  methods: {
    onImageLoad() {
        this.loaded = true;
    },
    getStyle(item) {
      const baseStyle = {
        position: 'absolute',
        left: `${item.left}px`,
        top: `${item.top}px`,
        width: `${item.width}px`,
        height: item.height ? `${item.height}px` : 'auto',
        fontFamily: item.fontFamily,
        fontSize: `${item.fontSize}px`,
        transform: `rotate(${item.angle}deg) scaleX(${item.scaleX}) scaleY(${item.scaleY})`,
        transformOrigin: 'top left',
        lineHeight: 'normal',
      };

      if (item.type === 'image') {
        if(item.name.includes('background')) {
          baseStyle.top = `${item.top}px`;
          baseStyle.left = `${item.left}px`;
          baseStyle.width = 0;
          this.backgroundImage = item.src;

          //load image
          let newImage = new Image();
          newImage.src = item.src;
          newImage.crossOrigin = "Anonymous";
          newImage.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = newImage.width;
            canvas.height = newImage.height;
            ctx.drawImage(newImage, 0, 0, newImage.width, newImage.height);
            let data = ctx.getImageData(0, 0, newImage.width, newImage.height).data;
            let r = 0, g = 0, b = 0, count = 0;
            for (let i = 0; i < data.length; i += 4) {
              if (data[i + 3] > 0) {
                r += data[i];
                g += data[i + 1];
                b += data[i + 2];
                count++;
              }
            }
            r = Math.round(r / count);
            g = Math.round(g / count);
            b = Math.round(b / count);
            this.backgroundColor = `rgb(${r}, ${g}, ${b})`;
          };


        } else {
          let imageUrl = item.src;
          let scale = Math.min(item.scaleX, item.scaleY);
          new fabric.Image.fromURL(imageUrl, newImage => {
            let scaleX = item.width * scale / newImage.width;
            let scaleY = item.height * scale / newImage.height;
            baseStyle.left = `${item.left}px`;
            baseStyle.top = `0px`;
            baseStyle.width = `${item.width*scaleX}px`;
            baseStyle.fontFamily = item.fontFamily;
            baseStyle.fontSize = `${item.fontSize}px`;
            baseStyle.transform = `rotate(${item.angle}deg) scaleX(${item.scaleX}) scaleY(${item.scaleY})`;
            baseStyle.name = item.name;
            baseStyle.transform = `scaleX(${scaleX}) scaleY(${scaleY})`;
          });
        }
      }

      return baseStyle;
    }
  },
  watch: {
    index() {
      this.loaded = false;
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  left: 0;
  margin: 0;
  padding: 0;
}
.container-fluid {
  position: relative;
  width: 50%;
  height: 100%;
  min-height: 540px;
  max-height: 960px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .container-fluid {
    width: 100%;
  }
}

@media print {
  .container-fluid {
    margin: 0;
    page-break-inside: avoid;
    zoom:1.4;
  }

  .no-print
  {
    display: none;
  }
}
</style>

