<template>
  <div ref="componentRef" style="margin: 0 auto; text-align: center; overflow: hidden;">
    <template v-if="this.kw && this.kw.products && this.canvas">
      <h4>
        Element: {{this.kw.products.length}} / {{this.getTotalProducts()}}
      </h4>
    </template>
    <canvas ref="canvas" :height="canvasHeight" :width="canvasWidth" v-if="orientation == 'h'"></canvas>
    <canvas ref="canvas" :height="canvasWidth" :width="canvasHeight" v-else></canvas>
  </div>
</template>

<script>
/* eslint-disable */
import {fabric} from "fabric";

export default {
  name: "PreviewCanvas",
  props: {
    orientation: {
      type: String,
      default: 'h'
    }
  },
  data: () => ({
    canvasWidth: 1920/2,
    canvasHeight: 1080/2,
    canvas: null,
    interval: null,
    iteratorTest: 0,
  }),
  methods: {
    getTotalProducts() {
      let productIndices = new Set();
      this.canvas.getObjects().forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let index = obj.name.split('_').pop();
          productIndices.add(index);
        }
      });
      return productIndices.size;
    },
    modifyCanvas() {
      const totalProducts = this.getTotalProducts();
      let selectedProducts = this.products;
      if(this.iteratorTest === (this.kw.products.length - totalProducts)) {
        this.iteratorTest = 0;
      } else if (this.iteratorTest + totalProducts > (this.kw.products.length - totalProducts)) {
        this.iteratorTest = 0;
      } else {
        this.iteratorTest += totalProducts;
      }

      this.canvas.getObjects().forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let [type, , index] = obj.name.split('_');
          let product = selectedProducts[parseInt(index) % totalProducts];
          let variable = product.variables.find(v => v.name === type);
          if (variable) {
            if (variable.type === 'image') {
              this.replaceCanvasImage(obj, variable);
            } else if (variable.type === 'text') {
              this.replaceCanvasText(obj, variable);
            }
          }
        }
      });
    },
    replaceCanvasImage(obj, variable) {
      let imageUrl = this.baseUrl + variable.pivot.value;
      let scale = Math.min(obj.scaleX, obj.scaleY);
      new fabric.Image.fromURL(imageUrl, newImage => {
        newImage.set({
          left: obj.left,
          top: obj.top,
          scaleX: obj.width * scale / newImage.width,
          scaleY: obj.height * scale / newImage.height,
          name: obj.name,
        });
        this.canvas.remove(obj);
        this.canvas.add(newImage);
        this.canvas.renderAll();
      });
    },
    replaceCanvasText(obj, variable) {
      obj.text = variable.pivot.value;
      this.canvas.renderAll();
    },
    drawCanvas() {
      this.canvas = new fabric.StaticCanvas(this.$refs.canvas);
      let json = this.orientation == 'h' ? this.data.canvas : this.data.canvasVertical;
      this.canvas.loadFromJSON(json, () => {
        this.canvas.renderAll();
        let newZoom = this.$refs.componentRef.clientWidth / this.canvasWidth;
        this.canvas.setZoom(newZoom);
        this.interval = setInterval(() => {
          this.modifyCanvas();
        }, 1800);
      });
    },
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  mounted() {
    this.$store.dispatch('canvas', this.$route.params.id).then(() => {
      this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId).then(() => {
        this.drawCanvas();
      });
    });
  },
  computed: {
    data() {
      return this.$store.state.canvas;
    },
    kw() {
      return this.$store.state.kw.products.products ?? [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
    products() {
      const totalProducts = this.getTotalProducts();
      let shuffledProducts = this.kw.products;
      return shuffledProducts.slice(this.iteratorTest, totalProducts+this.iteratorTest);
    }
  }
};
</script>
