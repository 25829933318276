<template>
  <v-main>
    <v-breadcrumbs :items="breamcrumbs" large></v-breadcrumbs>

    <v-card flat>
      <v-card-title class="d-flex align-center pe-2"
                    link
                    to="/"
      >
        <v-icon icon="mdi-table-column-plus-after"></v-icon> &nbsp;
        Spalten

        <v-spacer></v-spacer>

        <v-btn
            :to="`/database/` + $route.params.id + `/variables/new`"
            color="primary"
        >
          <v-icon icon="mdi-plus"></v-icon> &nbsp;
          hinzufügen
        </v-btn>

      </v-card-title>

      <v-divider/>
      <v-data-table :items="items">
        <template v-slot:item.type="{ item }">
          <v-chip color="secondary" v-if="item.type === 'text'">
            <v-icon icon="mdi-format-letter-case"></v-icon>
          </v-chip>
          <v-chip color="secondary" v-if="item.type === 'image'">
            <v-icon icon="mdi-file-image-outline"></v-icon>
          </v-chip>

          <v-btn
          color="primary"
          class="float-right"
          @click="deleteItem(item)"
          >
            <v-icon icon="mdi-delete"></v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: 'TemplateVariables',
  data () {
    return {
      breamcrumbs: [
        {
          title: 'Vorlagen',
          disabled: false,
          to: '/',
        },
        {
          title: this.$route.params.id,
          disabled: false,
          to: `/template/` + this.$route.params.id,
        },
        {
          title: 'Variablen',
          disabled: true,
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('templateVariables/fetchTemplateVariables', this.$route.params.id)
  },
  methods: {
    deleteItem(item) {
      confirm('Sind Sie sicher, dass Sie dieses Element löschen möchten?') && this.$store.dispatch('templateVariables/deleteTemplateVariable', item)
    }
  },
  computed: {
    items() {
      return this.$store.state.templateVariables.templateVariables || [];
    }
  },
}
</script>