<template>
  <v-card>
    <v-card-title class="d-flex align-center pe-2">
      <v-icon icon="mdi-palette-swatch"></v-icon> &nbsp;
      Vorlagen

      <v-spacer></v-spacer>

      <v-btn
          to="/template/new"
          color="primary"
          v-can="'priv_campaigns_schedule'"
          size="small"
          icon="mdi-plus"
      />

    </v-card-title>

    <v-divider/>

    <v-container fluid>
      <v-row dense v-if="loading">
        <template v-for="n in 4" v-bind:key="n">
          <v-col cols="6">
            <v-card>
              <v-skeleton-loader type="card" height="200px"></v-skeleton-loader>
            </v-card>
          </v-col>
        </template>
      </v-row>
    <v-row dense v-else>
      <template v-for="item in items" :key="item.id">
        <v-col cols="6">
          <v-card
              color="#fff3ef"
              theme="dark"
              :to="`/template/` + item.id"
          >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-card-title class="text-h6">
                    {{ item.name }}
                  </v-card-title>
                </v-col>
                <v-col
                    cols="6"
                >
                  <v-img class="my-1"
                         :src="`${baseUrl}${item.image}`"
                         height="128"
                         aspect-ratio="16/9"
                  ></v-img>

                </v-col>
                <v-col
                    cols="6"
                >
                  <v-img class="my-1"
                         :src="`${baseUrl}${item.image2}`"
                         height="128"
                         aspect-ratio="16/9"
                  ></v-img>
                </v-col>
              </v-row>


            <v-card-actions>
              <template v-if="!item.canvas">
                <v-icon>mdi-alert-circle</v-icon>
                Keine Vorlage elemente
              </template>
              <v-spacer></v-spacer>
              <v-btn
                  color="surface-variant"
                  icon="mdi-link"
                  variant="text"
                  size="small"
                  :to="`/online/` + item.id + `?name=` + item.name"
              ></v-btn>

              <v-btn
                  color="surface-variant"
                  icon="mdi-pencil"
                  variant="text"
                  size="small"
                  :to="`/template/` + item.id + `/edit`"
                  v-can="'priv_campaigns_schedule'"
              ></v-btn>
              <v-btn
                  color="surface-variant"
                  icon="mdi-delete"
                  variant="text"
                  size="small"
                  @click="deleteItem($event, item)"
                  v-can="'priv_campaigns_schedule'"
              ></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "TemplatesTable",
  data() {
    return {
      search: '',
    }
  },
  mounted() {
    this.$store.dispatch('template/fetchTemplates', this.$store.state.overrideHotelId);
  },
  updated() {
    this.$store.dispatch('template/fetchTemplates', this.$store.state.overrideHotelId);
  },
  computed: {
    items() {
      return this.$store.state.template.templates || [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
    loading() {
      return this.$store.state.template.loading;
    }
  },
  methods: {
    deleteItem($event, item) {
      $event.preventDefault();
      confirm('Sind Sie sicher, dass Sie dieses Element löschen möchten?') && this.$store.dispatch('template/deleteTemplate', item.id)
    },
  }
}
</script>