<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-spacer></v-spacer>
        <v-btn :to='`/template/`+ $route.params.id + `/kw/` + $route.params.kwId' icon="$close" variant="text"></v-btn>
      </v-card-title>
      <v-row>
        <v-col
            cols="12"
            sm="6"
        >
          <v-card>
            <v-card-title>
              <v-icon icon="mdi-gradient-horizontal"></v-icon> &nbsp;
              Element horizontal vorschau
            </v-card-title>

            <v-card-item>
              <PreviewCanvas/>
            </v-card-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :to="`/template/` + $route.params.id + `/canvas`"
                  color="primary"
                  v-can="'priv_campaigns_schedule'"
                  border
              >
                <v-icon icon="mdi-artboard"></v-icon> &nbsp;
                Element bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
            cols="12"
            sm="6"
        >
          <v-card>
            <v-card-title>
              <v-icon icon="mdi-gradient-vertical"></v-icon> &nbsp;
              Element vertikal vorschau
            </v-card-title>

            <v-card-item>
              <PreviewCanvas orientation="v"/>
            </v-card-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  :to="`/template/` + $route.params.id + `/canvasVertical`"
                  color="primary"
                  v-can="'priv_campaigns_schedule'"
                  border
              >
                <v-icon icon="mdi-artboard"></v-icon> &nbsp;
                Element bearbeiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-main>
</template>
<script>
import PreviewCanvas from "@/views/components/PreviewCanvas";

export default {
  components: {PreviewCanvas},
};
</script>
