<template>
  <v-main>
    <v-breadcrumbs :items="breamcrumbs" large></v-breadcrumbs>

    <ProductEditCustomer ref="product" />

    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-drag"></v-icon> &nbsp;
        Artikelzuweisung {{this.$store.state.kw.products.products ? 'für ' + this.$store.state.kw.products.products.name : ''}} (Anzahl: {{selected.length}})
        <v-btn
            :to="`/template/` + $route.params.id + `/random/` + $route.params.kwId"
            color="primary"
            size="small"
            class="ml-2"
        >
          <v-icon icon="mdi-eye"></v-icon> &nbsp;
          Vorschau
        </v-btn>

<!--        <v-btn-->
<!--            :to="`/template/` + $route.params.id + `/randomVertical/` + $route.params.kwId"-->
<!--            color="primary"-->
<!--            size="small"-->
<!--            class="ml-1"-->
<!--            @click="dialog = true"-->
<!--        >-->
<!--          <v-icon icon="mdi-eye"></v-icon> &nbsp;-->
<!--          Vorschau vertikal-->
<!--        </v-btn>-->


        <v-spacer/>
        <v-btn :to='`/template/` + $route.params.id' icon="$close" variant="text"></v-btn>

        <v-snackbar :timeout="1000" v-model="isSaved" location="top right" color="success">
          <v-icon icon="mdi-selection-drag"></v-icon> &nbsp;
          Auswahl speichern
        </v-snackbar>
      </v-card-title>

      <v-table style="border: 1px solid #e0e0e0;">
        <draggable v-model="selectedItems" @end="updateOrder" tag="tbody">
          <template #item="{element}">
              <tr :key="element.id" v-bind:class="{'item-selected': true}" style="cursor: move;">
                  <td>
                    <div style="display: inline-flex;align-items: center;">
                      <v-icon>
                        mdi-drag
                      </v-icon>
                    </div>
                  </td>
                  <template v-for="(item) in element.variables" v-bind:key="item.id">
                    <td v-if="typeof item.pivot.value === 'string' && (item.pivot.value.includes('.png') || item.pivot.value.includes('.jpg') || item.pivot.value.includes('.jpeg'))">
                      <v-card class="my-2" elevation="2" rounded>
                        <v-img
                            :src="`${this.baseUrl}${item.pivot.value}`"
                            cover
                            max-height="200px"
                        ></v-img>
                      </v-card>
                    </td>
                    <td v-else>{{item.pivot.value}}</td>
                  </template>
                </tr>
          </template>
        </draggable>
      </v-table>


      <v-card-title class="d-flex align-center pe-2">
        <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            density="compact"
            label="Suche"
            single-line
            flat
            hide-details
            variant="solo-filled"

            class="pr-2"
        ></v-text-field>


        <v-btn
            :to="`/database/` + databaseId + `/addProduct`"
            color="primary"
        >
          <v-icon icon="mdi-plus"></v-icon> &nbsp;
          hinzufügen
        </v-btn>

      </v-card-title>

      <v-divider/>
      <v-data-table v-model:search="search" :items="items" :headers="headers">
        <template v-slot:item="{ item }">
          <tr v-bind:class="{'deactive': !item.active}">
            <template v-for="(value,key) in item" v-bind:key="key">
              <td v-if="typeof value === 'string' && (value.includes('.png') || value.includes('.jpg') || value.includes('.jpeg'))">
                <v-card class="my-2" elevation="2" rounded>
                  <v-img
                      :src="`${this.baseUrl}${value}`"
                      cover
                      max-height="200px"
                  ></v-img>
                </v-card>
              </td>
              <td v-else-if="key === 'id'">
                <input type="checkbox" :value="item.id" v-model="selected" @change="updateSelection">
              </td>
              <template v-else-if="key === 'active'">
              </template>
              <td v-else>
                {{value}}
              </td>
            </template>
            <td>



              <v-btn
                  color="primary"
                  size="small"
                  class="float-right"
                  @click="deleteItem(item.id)"
                  v-can="'priv_campaigns_schedule'"
              >
                <v-icon icon="mdi-delete"></v-icon>
              </v-btn>
              <v-btn
                  color="primary"
                  size="small"
                  class="float-right mr-2"
                  v-can="'priv_campaigns_schedule'"
                  :to="`/database/` + databaseId + `/editProduct/` + item.id"
              >
                <v-icon icon="mdi-pencil"></v-icon>
              </v-btn>

              <template v-if="item.active">
                <v-btn
                    color="gray"
                    class="float-right mr-2"
                    size="small"
                    v-can="'priv_campaigns_schedule'"
                    @click="this.deactivateProduct(item.id)"
                >
                  <v-icon icon="mdi-eye-off"></v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                    color="success"
                    class="float-right mr-2"
                    size="small"
                    v-can="'priv_campaigns_schedule'"
                    @click="this.activateProduct(item.id)"
                >
                  <v-icon icon="mdi-eye"></v-icon>
                </v-btn>
              </template>
            </td>

          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-main>
</template>

<style scoped>
.actionbuttons .v-btn {
  background: #A22E32 !important;
  color: white !important;
}

.item-selected {
  background: #A22E32 !important;
  color: white !important;
}

input[type="checkbox"] {
  margin-left: 10px;
  zoom: 1.5;
}

.deactive {
  background: #2e6ea2 !important;
  color: white !important;
}
</style>

<script>
import draggable from 'vuedraggable';
import ProductEditCustomer from "@/views/components/ProductEditCustomer";

export default {
  components: {
    draggable,
    ProductEditCustomer,
  },
  data () {
    return {
      isDialogOpen: false,
      isSaved: false,
      selected: [],
      search: '',
      breamcrumbs: [
        {
          title: 'Startseite',
          disabled: false,
          to: '/',
        },
        {
          title: 'Vorlagen',
          disabled: false,
          to: `/template/` + this.$route.params.id,
        },
        {
          title: 'Artikelzuweisung',
          to: `/template/` + this.$route.params.id + `/kw/assign`,
        },
      ],
    }
  },
  methods: {
    deleteItem(item) {
      confirm('Sind Sie sicher, dass Sie dieses Product löschen möchten?') && this.$store.dispatch('database/deleteProduct', item).then(() => {
        this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId).then(() => {
          this.selected = this.selectedItems.map(product => product.id);
        });
      });
    },
    activateProduct(id) {
      this.$store.dispatch('database/activateProduct', id).then(() => {
        this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId).then(() => {
          this.selected = this.selectedItems.map(product => product.id);
        });
      });
    },
    deactivateProduct(id) {
      this.$store.dispatch('database/deactivateProduct', id).then(() => {
        this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId).then(() => {
          this.selected = this.selectedItems.map(product => product.id);
        });
      });
    },
    updateOrder(event) {
      this.selected = this.swapElements(this.selected, event.oldIndex, event.newIndex);
      this.updateSelection();
    },
    swapElements(array, oldIndex, newIndex) {
      if (oldIndex < array.length && newIndex < array.length) {
        const temp = array[oldIndex];
        array[oldIndex] = array[newIndex];
        array[newIndex] = temp;
      }
      return array;
    },
    updateSelection(event) {
      if(event && event.target.checked) {
        this.$refs.product.open(this.selected[this.selected.length - 1]);
      }
      this.$store.dispatch('kw/assignProduct', {
        kwId: this.$route.params.kwId,
        products: this.selected,
      });
      this.isSaved = true;
    },
  },
  mounted() {
    this.$store.dispatch('kw/fetchKwProducts', this.$route.params.kwId).then(() => {
      this.selected = this.selectedItems.map(product => product.id);
    });
  },
  computed: {
    headers() {
      let headers = Object.keys(this.items[0] ?? {}).filter(key => key !== 'active').map(key => {
        return {
          title: key,
          key: key,
        }
      });
      return headers.concat({
        title: 'Aktionen',
        key: 'actions',
        sortable: false,
      });
    },
    databaseId() {
      return (this.$store.state.kw.products && this.$store.state.kw.products.products && this.$store.state.kw.products.products.template
          && this.$store.state.kw.products.products.template.fkSscDatabaseId) ?? null;
    },
    items() {
      return this.$store.state.kw.products.all ?? [];
    },
    selectedItems() {
      return (this.$store.state.kw.products && this.$store.state.kw.products.products && this.$store.state.kw.products.products.products) ?? [];
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
  // watch: {
  //   selected: {
  //     handler: function() {
  //       this.items.sort((a, b) => {
  //         const indexA = this.selected.indexOf(a.id);
  //         const indexB = this.selected.indexOf(b.id);
  //
  //         if (indexA !== -1 && indexB !== -1) {
  //           return indexA - indexB;
  //         } else if (indexA !== -1) {
  //           return -1;
  //         } else if (indexB !== -1) {
  //           return 1;
  //         } else {
  //           return 0;
  //         }
  //       });
  //     },
  //     deep: true
  //   }
  // }
}
</script>