<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        Datenbankstellung
        <v-spacer></v-spacer>
        <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
      </v-card-title>
      <v-card flat>

        <v-card-text>
          <v-text-field
              v-model="name"
              label="Namen"
              placeholder="Namen"
              prepend-icon="mdi-file-document-outline"
              outlined
              dense
              filled
              color="primary"
          ></v-text-field>

          <v-col
            class="text-center">
            <v-btn @click="saveTemplate"
                   :disabled="!name"
                   color="primary"
                   class="text-right"
            >
              Speichern
            </v-btn>
            </v-col>

        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<script>
  export default {
    data: () => ({
      name: null,
    }),
    methods: {
      saveTemplate() {
        this.$store.dispatch('database/createDatabase', {'name': this.name, 'hotelId': this.$store.state.overrideHotelId}).then(() => {
            this.$router.push("/");
        });
      },
    },
  };
</script>
