/* eslint-disable */
import * as VueRouter from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import Main from "../views/layouts/Main";
import DatabaseOverview from "@/views/components/Database/DatabaseOverview";
import EditCanvas from "@/views/components/Template/EditCanvas";
import EditCanvasVertical from "@/views/components/Template/EditCanvasVertical";
import MakeCanvas from "@/views/components/Template/MakeCanvas";
import KWList from "@/views/components/Template/KW/KWList";
import KWNew from "@/views/components/Template/KW/KWNew";
import KWAssignProducts from "@/views/components/Template/KW/KWAssignProducts";
import ApiDocs from "@/views/layouts/template/ApiDocs";
import ListHotels from "@/views/components/Home/ListHotels";
import DatabaseManage from "@/views/components/Database/DatabaseManage";
import DatabaseVariables from "@/views/components/Database/DatabaseVariables";
import DatabaseVariablesNew from "@/views/components/Database/DatabaseVariablesNew";
import DatabaseProductNew from "@/views/components/Database/DatabaseProductNew";
import DatabaseProductEdit from "@/views/components/Database/DatabaseProductEdit";
import TemplateList from "@/views/components/Template/TemplateList";
import TemplateNew from "@/views/components/Template/TemplateNew";
import DatabaseNew from "@/views/components/Database/DatabaseNew";
import KWEdit from "@/views/components/Template/KW/KWEdit";
import TemplateEdit from "@/views/components/Template/TemplateEdit";
import OnlineCanvas from "@/views/components/Template/OnlineCanvas";
import OnlineAllCanvas2 from "@/views/components/Template/OnlineAllCanvas2";
import OnlineAllCanvas from "@/views/components/Template/OnlineAllCanvas";
import WebsiteNew from "@/views/components/Websites/WebsiteNew";
import PDFOnlineAllCanvas2 from "@/views/components/Template/PDF/PDFOnlineAllCanvas2";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Main,
    children: [
      {
        path: '/database/',
        children: [
            {
              path: 'new',
              component: DatabaseNew,
            },
            {
              path: ':id',
              component: DatabaseOverview,
            },
            {
              path: ':id/manage',
              component: DatabaseManage,
            },
            {
              path: ':id/variables',
              component: DatabaseVariables,
            },
            {
              path: ':id/variables/new',
              component: DatabaseVariablesNew,
            },
            {
              path: ':id/addProduct',
              component: DatabaseProductNew,
            },
            {
              path: ':id/editProduct/:productId',
              component: DatabaseProductEdit,
            },
        ]
      },
      {
        path: '/',
        component: TemplateList,
      },
      {
        path: '/template/new',
        component: TemplateNew,
      },
      {
        path: '/template/:id',
        component: KWList,
      },
      {
        path: '/template/:id/edit',
        component: TemplateEdit,
      },
      {
        path: '/template/:id/kw/new',
        component: KWNew,
      },
      {
        path: '/template/:id/kw/:kwId',
        component: KWAssignProducts,
      },
      {
        path: '/template/:id/kw/:kwId/edit',
        component: KWEdit,
      },
      {
        path: '/template/:id/canvas',
        component: EditCanvas,
      },
      {
        path: '/template/:id/canvasVertical',
        component: EditCanvasVertical,
      },
      {
        path: '/template/:id/random/:kwId',
        component: MakeCanvas,
      },
      {
        path: '/docs/api',
        component: ApiDocs,
      },
      {
        path: '/hotels',
        component: ListHotels,
      },
      {
        path: '/website/',
        children: [
          {
            path: 'new',
            component: WebsiteNew,
          },
          {
            path: ':id/edit',
            component: WebsiteNew,
          },
        ]
      }
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/online/:id',
    component: OnlineAllCanvas,
    name: 'OnlineCanvas',
  },
  {
    path: '/online/:id/:canvasId',
    component: OnlineAllCanvas2,
    name: 'OnlineCanvas2',
  },
  {
    path: '/pdf/:id/:canvasId',
    component: PDFOnlineAllCanvas2,
    name: 'PDFOnlineAllCanvas2',
  },
  // {
  //   path: '/online1/:id',
  //   component: OnlineAllCanvas,
  //   name: 'OnlineCanvas',
  // },
];

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const permissions = JSON.parse(localStorage.getItem('permissions'));
//   const requiredPermission = 'priv_hotels_all_view';
//
//   //to.matched.some(record => record.meta.requiresAdmin)
//   if (to.path === '/' && permissions.includes(requiredPermission)) {
//     next({ path: '/hotels' });
//   } else {
//     next();
//   }
// });


router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (token) {
    // store.dispatch('checkToken', token).then(() => {
    //   next();
    // }).catch(() => {
    //   next('/login');
    // });
    next();
  } else {
    const allowedRoutes = ['PDFOnlineAllCanvas2', 'OnlineCanvas', 'OnlineCanvas2', 'Login'];

    if (allowedRoutes.includes(to.name)) {
      next();
    } else {
      next('/login');
    }
  }
});


export default router;

