<template>
  <div v-if="this.website" :style="`background-color: ${this.website.web_background}; min-height:100vh; width:100%; overflow: hidden;`">

    <v-row class="d-flex justify-center align-center">
      <v-col cols="2" class="justify-center align-center d-none d-sm-flex">
        <v-btn size="x-large" color="surface-variant"
               variant="text"
               :href="`${this.website.web_url}`"
               dark>
          <v-icon size="x-large" left>mdi-home</v-icon>
          <v-tooltip
              activator="parent"
              location="bottom"
          >Zurück zur Webseite</v-tooltip>
        </v-btn>
      </v-col>

      <v-col class="d-flex justify-center">
        <router-link :to="`/online/${this.$route.params.id}`">
          <img :src="this.baseUrl + this.website.web_image" alt="web_image" style="width:100%; max-width: 900px;" v-if="this.website.web_image && this.orientation === 'h'">
          <img :src="this.baseUrl + this.website.web_v_image" alt="web_image" style="width:100%; max-width: 540px;" v-if="this.website.web_v_image && this.orientation === 'v'">
        </router-link>
      </v-col>

      <v-col cols="2" class="justify-center align-center d-none d-sm-flex">
          <router-link :to="`/online/${this.$route.params.id}`">
            <v-btn size="x-large" color="surface-variant"
                   variant="text"
                   dark
                   alt="Angebote"
            >
              <b>A</b>
              <v-tooltip
                  activator="parent"
                  location="bottom"
              >Angebote Übersicht</v-tooltip>
            </v-btn>
          </router-link>


          <v-btn size="x-large" variant="text" dark @click="generateReport(false)">
            <v-icon size="x-large" left>mdi-download</v-icon>
            <v-tooltip
                activator="parent"
                location="bottom"
            >Als PDF Speichern</v-tooltip>
          </v-btn>

          <v-btn size="x-large" variant="text" @click="generateReport(true)"
          >
            <v-icon size="x-large" left>mdi-printer</v-icon>
            <v-tooltip
                activator="parent"
                location="bottom"
            >Als PDF zum Drucken Speichern (ohne Hintergrund)</v-tooltip>
          </v-btn>
      </v-col>
    </v-row>


    <div class="justify-center">
      <SlideshowOnline :id="this.$route.params.canvasId" v-if="this.$route.params.canvasId" :website="this.website"/>
    </div>
    <vue3-html2pdf
        filename="Angebot"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-orientation="portrait"
        :manual-pagination="true"
        @has-downloaded="hasDownloaded"
        v-if="isMounted"
        ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div :style="`background-color: ${ print ? 'white' : this.website.web_background};width:100%;margin: 0 auto;`">
          <SlideshowOnlineAll :id="this.$route.params.canvasId" v-if="this.$route.params.canvasId" :website="this.website" :print="print"/>
        </div>
      </template>
    </vue3-html2pdf>

  </div>
</template>
<script>
import SlideshowOnline from "@/views/components/Template/SlideshowOnline";
import SlideshowOnlineAll from "@/views/components/Template/PDF/SlideshowOnlineAll";
import Vue3Html2pdf from "vue3-html2pdf";

export default {
  components: {SlideshowOnline, Vue3Html2pdf, SlideshowOnlineAll},
  data: () => ({
    orientation: null,
    slides: 0,
    slide: 1,
    id: 0,
    print:false,
    isMounted: true,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('website/fetchWebsite', this.$route.params.id);
  },
  methods: {
    generateReport(print = false) {
      this.print = print;
      this.$refs.html2Pdf.generatePdf()
    },
    hasDownloaded() {
      this.remount();
    },
    remount() {
      this.isMounted = false;
      this.$nextTick(() => {
        this.isMounted = true;
      });
    },
  },
  computed: {
    website() {
      return this.$store.state.website.website;
    },
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
  },
};
</script>
