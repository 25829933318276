import axios from 'axios';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.location.hostname === 'localhost'
    ? "https://localhost/api/v1"
    : "https://yourscreen-setup.de/api/v1";

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default axios;
