<template>
  <v-main>
    <v-card flat>
      <v-card-title class="d-flex align-center pe-2">
        <v-icon icon="mdi-hexagon-multiple"></v-icon> &nbsp;
        Vorlagenerstellung
        <v-spacer></v-spacer>
        <v-btn icon="$close" variant="text" @click="$router.go(-1)"></v-btn>
      </v-card-title>
      <v-card flat>
        <v-card-text>
          Das ist Ihr Bild. Bitte markieren Sie die Bereiche, in die Sie Vorlagen einfügen möchten.
        </v-card-text>

        <v-snackbar v-model="isSaved" location="top right" color="success" :timeout="3000">
          <v-icon left>
            mdi-check
          </v-icon>
          Gespeichert
        </v-snackbar>

        <v-card-text class="text-center" v-if="data && data.database && data.database.products">
          <v-btn
              class="ma-1"
              v-for="variable in data.database.products[0].variables"
              v-bind:key="variable.id"
              @click="createVariableElement(variable)"
          >
            <v-icon left v-if="variable.type === 'text'">
              mdi-text-box
            </v-icon>
            <v-icon left v-else>
              mdi-image
            </v-icon>

            {{variable.name}}
          </v-btn>
        </v-card-text>

        <div class="canvas-container">
            <canvas ref="canvas" :height="canvasHeight" :width="canvasWidth" v-if="image" style="max-width: 100%; height: auto;"></canvas>

          <div v-if="selectedObject" :style="toolbarStyle" class="floating-toolbar">
            <v-card
             class="pa-2"
                    style="display: inline-flex;"
            >

              <template v-if="this.selectedObject.type === 'textbox'">
                <v-btn class="mr-1" rounded size="small" icon @click="applyTextStyle('bold')">
                  <v-icon>mdi-format-bold</v-icon>
                </v-btn>
                <v-btn class="mr-1" rounded size="small" icon @click="applyTextStyle('italic')">
                  <v-icon>mdi-format-italic</v-icon>
                </v-btn>
                <v-select
                    v-model="selectedObject.fontFamily"
                    :items="['Arial Rounded MT Bold', 'Arial MT', 'Helvetica Neue', 'Times New Roman', 'Impact']"
                    label="Font Family"
                    dense
                    outlined
                    hide-details
                    class="ml-1"
                    style="min-width: 200px;"
                    density="compact"
                    @update:modelValue="renderAll"
                ></v-select>
                <!-- Font size input -->
                <v-text-field
                    v-model="selectedObject.fontSize"
                    label="Size"
                    dense
                    outlined
                    hide-details
                    class="ml-1 mr-2"
                    style="min-width: 80px"
                    density="compact"
                    @update:modelValue="renderAll"
                ></v-text-field>
              </template>
              <v-btn class="mr-1" rounded size="small" icon @click="sendObjectBackward">
                <v-icon>mdi-layers-minus
                </v-icon>
              </v-btn>
              <v-btn class="mr-1" rounded size="small" icon @click="bringObjectForward">
                <v-icon>mdi-layers-plus</v-icon>
              </v-btn>
              <v-btn class="mr-1" rounded size="small" icon @click="deleteObject">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-card>
            <div style="background: white; font-weight: bolder; text-align: center;">
              <template v-if="selectedObjectType === 'image'">
                <div class="font-weight-bold capitalize-first-letter"><v-icon>mdi-image</v-icon> {{selectedObject.name}}</div>
              </template>
              <template v-else>
                <div class="font-weight-bold capitalize-first-letter"><v-icon>mdi-text-box</v-icon> {{selectedObject.name}}</div>
              </template>
            </div>
          </div>
        </div>

        <v-card-text class="text-center">
          <v-btn color="primary" @click="saveCanvas">
            <v-icon left>
              mdi-content-save
            </v-icon>
            Speichern
          </v-btn>
        </v-card-text>

        <v-card-text class="text-center">
          <v-btn color="secondary" @click="resetCanvas">
            <v-icon left>
              mdi-restart
            </v-icon>
            Reset
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
</template>

<style>
.canvas-container {
  position: relative;
  margin:0 auto;
  width: 50%;
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*height: 100%;*/
}

.floating-toolbar2 {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  height: 100%; /* Ensure the div has a height */
  border: 2px dashed black; /* Dotted border */
  background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
  padding: 5px;
  border-radius: 5px;
}
.capitalize-first-letter::first-letter {
  text-transform: uppercase;
}
</style>

<script>
/* eslint-disable */
import {fabric} from "fabric";

fabric.Object.prototype.centeredScaling = true;
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.includeDefaultValues = true;

  export default {
    name: "NewTemplate",
    components: {},

    data: () => ({
      selectedObject: null,
      canvasWidth: 1920 /2,
      canvasHeight: 1080 /2,
      file: null,
      toolbarStyle: null,
      overlayStyle: null,
      isSaved: false,
    }),
    methods: {
      selection(e) {
        this.selectedObject = e.selected ? e.selected[0] : null;
        this.updateToolbarPosition();
      },
      modified(e) {
        this.selectedObject = e.target;
        this.updateToolbarPosition();
      },
      renderAll() {
        this.canvas.renderAll();
      },
      drawCanvas() {
        this.canvas = new fabric.Canvas(this.$refs.canvas, {
          hasControls: true,
          preserveObjectStacking: true,
          stopContextMenu: true,
        });

        this.canvas.on({
          'selection:created': this.selection,
          'selection:updated': this.selection,
          'selection:cleared': this.selection,
          'object:modified': this.modified,
          'object:rotated': this.modified,
          'object:scaled': this.modified,
          'object:moved': this.modified,
          'object:rotating': this.modified,
          'object:moving': this.modified
        });

        this.canvas.on('selection:cleared', () => {
          this.selectedObject = null;
        });

        if(this.data && this.data.canvas) {
          this.canvas.loadFromJSON(this.data.canvas, () => {
            this.canvas.renderAll();
          });
        } else {
          // Creating an image element and setting its source
          const imgElement = document.createElement("img");
          imgElement.src = this.baseUrl + this.image;

          imgElement.onload = () => {
            const imgInstance = new fabric.Image(imgElement, {
              scaleX: this.canvas.width / imgElement.width,
              scaleY: this.canvas.height / imgElement.height,
              selectable: false,
              name: "background_system"
            });
            this.addObject(imgInstance);
          };
        }
      },
      saveCanvas() {
        const json = this.canvas.toJSON(['name', 'selectable']);
        this.$store.dispatch('saveCanvas', {template_id: this.$route.params.id, canvas: json});
        this.isSaved = true;
        console.log(json);
      },
      resetCanvas() {
        this.$store.dispatch('saveCanvas', {template_id: this.$route.params.id, canvas: null});
        this.isSaved = true;
      },
      createVariableElement(variable, productIndex = 0) {
        let uniqueVariableName = `${variable.name}_product_${productIndex}`;

        while (this.isVariableNameInCanvas(uniqueVariableName)) {
          productIndex++;
          uniqueVariableName = `${variable.name}_product_${productIndex}`;
        }

        const variableInProduct = this.data.database.products[productIndex].variables.find(v => v.name === variable.name);
        if (!variableInProduct) {
          console.error(`Variable ${variable.name} not found in product index ${productIndex}`);
          return;
        }

        if(variableInProduct.type === 'image') {
          this.makeCanvasImage(variableInProduct, uniqueVariableName);
          return;
        }

        const obj = new fabric.Textbox(uniqueVariableName, {
          text: variableInProduct.pivot.value,
          hasControls: true,
          centeredScaling: false,
          name: uniqueVariableName,
        });
        obj.setControlsVisibility({
          tl: false,
          bl: false,
          tr: false,
          br: false,
          mt: false,
          mb: false
        });
        this.addObject(obj);
      },
      isVariableNameInCanvas(name) {
        return this.canvas.getObjects().some(obj => obj.name === name);
      },
      makeCanvasImage(variable, uniqueVariableName) {
        new fabric.Image.fromURL(this.baseUrl + variable.pivot.value, obj => {
          obj.set({
            left: 100,
            top: 100,
            angle: 0,
            padding: 10,
            cornersize: 10,
            hasRotatingPoint: true,
            hasControls: true,
            name: uniqueVariableName,
            lockRotation: true,
            srcFromAttribute: true,
          });
          obj.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false
          });
          this.addObject(obj);
        });
      },
      addObject(obj) {
        this.canvas.centerObject(obj);
        this.canvas.add(obj);
        this.canvas.renderAll();
      },
      applyTextStyle(style) {
        if (this.selectedObject && this.selectedObject.type === 'textbox') {
          if (style === 'bold') {
            this.selectedObject.set('fontWeight', this.selectedObject.fontWeight === 'bold' ? 'normal' : 'bold');
          } else if (style === 'italic') {
            this.selectedObject.set('fontStyle', this.selectedObject.fontStyle === 'italic' ? 'normal' : 'italic');
          }
          this.canvas.renderAll();
        }
      },

      sendObjectBackward() {
        if (this.selectedObject) {
          this.canvas.sendBackwards(this.selectedObject);
          this.canvas.renderAll();
        }
      },
      bringObjectForward() {
        if (this.selectedObject) {
          this.canvas.bringForward(this.selectedObject);
          this.canvas.renderAll();
        }
      },
      deleteObject() {
        this.canvas.remove(this.canvas.getActiveObject());
        this.canvas.renderAll();
      },
      updateToolbarPosition() {
        if (!this.selectedObject) return;

        const boundingRect = this.selectedObject.getBoundingRect();
        this.toolbarStyle = {
          position: 'absolute',
          left: `${boundingRect.left}px`,
          top: `${boundingRect.top + boundingRect.height + 10}px`,
          zIndex: 1000
        };
      },
    },
    mounted() {
      this.$store.dispatch('canvas', this.$route.params.id);

      setTimeout(() => {
        this.drawCanvas();
      }, 1000);
    },
    computed: {
      isLoading() {
        return this.$store.state.isLoading;
      },
      image() {
        return this.$store.state.canvas.image;
      },
      data() {
        return this.$store.state.canvas;
      },
      baseUrl() {
        return this.$store.state.config.media;
      },
    },
  };
</script>
