<template>
  <template v-if="this.data && this.data.kw && slides != 0">
    <div v-for="n in slides" :key="n" v-bind:class="{ 'printer': print }">
      <template v-if="n % 2 !== 0">
        <div class="content-print">
          <img :src="this.baseUrl + this.website.web_image" alt="web_image" style="width:100%; max-width: 900px;" v-if="this.website.web_image && this.orientation === 'h'">
          <img :src="this.baseUrl + this.website.web_v_image" alt="web_image" style="width:100%; max-width: 540px;" v-if="this.website.web_v_image && this.orientation === 'v'">
        </div>
        <div class="text-center">
          <h1>{{this.website.pdf_title}}</h1>
        </div>
        <div class="content-print">
            <span>
                Gültig ab:
                {{this.data.kw[0].showstartdate ? this.data.kw[0].showstartdate : this.data.kw[0].startdate }}
                bis
                {{this.data.kw[0].showenddate ? this.data.kw[0].showenddate : this.data.kw[0].enddate }}
            </span>
        </div>
      </template>
      <div class="content-print">
        <PDFTestAllObjects :orientation="orientation" :index="(n-1)*this.totalOnCanvas" :data="this.data"
                           style="transform: scale(0.82); transform-origin: top left; width: 117.05%; height: 443px;"
        />
      </div>
      <div class="text-center" v-if="n % 2 === 0 && print" style="font-size: 0.7em;">
        <b>{{this.website.pdf_bottom}}</b>
      </div>
      <div class="page" v-if="n % 2 === 0 && n !== slides"></div>
    </div>
  </template>
</template>

<script>
/* eslint-disable */
import PDFTestAllObjects from "@/views/components/Template/PDF/PDFTestAllObjects";

export default {
  components: {PDFTestAllObjects},
  props: {
    id: {
      type: Number,
      required: true,
    },
    website: {
      type: Object,
      required: true,
    },
    print: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    orientation: null,
    totalOnCanvas: 0,
    slides: 0,
    slide: 1,
  }),
  mounted() {
    this.orientation = window.innerWidth > window.innerHeight ? 'h' : 'v';

    this.$store.dispatch('canvas', this.id).then(() => {
      if(this.data && this.data.kw && this.data.kw[0] && this.data.kw[0].products.length) {
        this.totalOnCanvas = this.getTotalProducts();
        this.slides = this.data.kw[0].products.length / this.totalOnCanvas;
      }
    });
  },
  methods: {
    gotoSlide(n) {
      this.slide = n;
    },
    getTotalProducts() {
      let productIndices = new Set();
      let canvas = this.orientation === 'h' ? JSON.parse(this.data.canvas) : JSON.parse(this.data.canvasVertical);
      if(!canvas) return 0;
      canvas.objects.forEach(obj => {
        if (obj.name.indexOf('product') !== -1) {
          let index = obj.name.split('_').pop();
          productIndices.add(index);
        }
      });
      return productIndices.size;
    },
  },
  computed: {
    data() {
      return this.$store.state.canvas;
    },
    baseUrl() {
      return this.$store.state.config.media;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
}
</script>

<style lang="scss">
.page {
  page-break-inside: avoid !important;
  page-break-after: always !important;
}
.content-print {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.printer {
  .background_system {
    display: none;
  }
}
</style>